import { common } from "../fr/common"
import { footer } from "../fr/footer"
import { header } from "../fr/header"
import { TestimonialFrench } from "./TestimonialFrench"

const additionalTrans = {
  "meta_title": "Apportez aisance et simplicité à l’engagement des employés",
  "meta_desc":
    "Enrichissez votre culture d'entreprise en améliorant l'engagement des employés avec nos récompenses pour les employés, reconnaissance sur place, rétroaction des employés et solutions de bien-être en entreprise conçues pour la main-d'œuvre mondiale d'aujourd'hui",
  "home_title":
    "Apportez <1>aisance et simplicité à</1> l’engagement des employés",
  "Enrich your company culture by improving employee engagement with our employee rewards,<br/> on-spot recognition, employee feedback, and corporate wellness solutions designed for today's global workforce.":
    "Enrichissez votre culture d'entreprise en améliorant l'engagement des employés avec nos récompenses pour les employés,<br/> reconnaissance sur place, rétroaction des employés et solutions de bien-être en entreprise conçues pour la main-d'œuvre mondiale d'aujourd'hui.",
  "Our Partners in Expansion": "NOS PARTENAIRES EN EXPANSION",
  "Trusted by over 400 Companies Around the World":
    "Approuvé par plus de 400 entreprises à travers le monde",
  "See more clients": "Voir plus de clients",
  "Trusted Partners": "PARTENAIRES DE CONFIANCE",
  "Making Employee Engagement Fun & Easy in over 400+ companies across the globe":
    "Rendre l'engagement des employés agréable et facile dans plus de 400 entreprises à travers le monde",
  "Read our success stories to discover how Vantage Circle helped companies connect, support, and recognize their workforce to transform their work culture and boost employee engagement.":
    "Lisez nos histoires de réussite pour découvrir comment Vantage Circle a aidé les entreprises à se connecter, à soutenir et à reconnaître leur personnel pour transformer leur culture de travail et renforcer l'engagement des employés.",
  "Check Success Stories": "Lisez les histoires de réussite",
  "Employee<br/>Engagement": "Engagement<br/> des employés",
  "Increase<br/>in Employee Participation":
    "D'augmentation de la participation des employés",
  "Reduction in<br/>Employee Absenteeism":
    "Réduction de l'absentéisme des employés",
  "Increase in<br/>Peer-to-peer recognition":
    "Augmentation de la reconnaissance entre pairs",
  "Our Solutions": "Nos Solutions",
  "The All-in-one Employee Engagement and Wellness Platform":
    "La plateforme tout-en-un d'engagement et de bien-être des employés",
  "Our employee engagement platform combines rewards<br/> and recognition, employee benefits, employee feedback, and wellness programs into a unified total solution.":
    "Notre plateforme d'engagement des employés combine récompenses et reconnaissance, avantages sociaux,<br/> rétroaction des employés et programmes de bien-être dans une solution globale unifiée.",
 " prod_vantagerewards": "<0>Vantage</0><1>Rewards</1>",
  "prod_vantageperks": "<0>Vantage</0><1>Perks</1>",
  "prod_vantagepulse": "<0>Vantage</0><1>Pulse</1>",
  "prod_vantagefit": "<0>Vantage</0><1>Fit</1>",
  "Know More": "En savoir plus",
  "Vantage Rewards is a powerful SaaS-based reward and recognition platform that is easy to use and makes the employee recognition process easy, fun, cost-effective, and globally accessible. Enable your organization to recognize and appreciate in a meaningful yet seamless way with Vantage Rewards.":
    "Vantage Rewards est une puissante plate-forme de récompense et de reconnaissance basée sur SaaS qui est facile à utiliser et rend le processus de reconnaissance des employés facile, agréable, rentable et accessible dans le monde entier. Permettez à votre organisation de reconnaître et d'apprécier de manière significative mais transparente avec Vantage Rewards.",
  "Vantage Perks is a comprehensive employee benefits platform that provides exclusive corporate deals, discounts, and cashback on a global catalog of top online and offline brands. Prioritizes your employees' financial well-being by enabling them to save money with Vantage Perks.":
    "Vantage Perks est une plate-forme d'avantages sociaux et de remises qui permet aux employés d'économiser de l'argent tout en faisant leurs achats avec des offres de remise en espèces et des autres remises. Donne la priorité au bien-être financier de vos employés en leur permettant d'économiser de l'argent avec Vantage Perks.",
  "Vantage Pulse is a powerful employee survey tool that lets you collect, measure, and act on real-time employee feedback. Uncover actionable data, predict employee behavior and get an accurate picture of your organization's employee engagement levels with Vantage Pulse.":
    "Vantage Pulse est un outil avancé d'enquête auprès des employés qui permet aux organisations de mener des sondages d'engagement opportunes de manière significative. Découvrez des données exploitables, prédisez le comportement des employés et obtenez une image précise des niveaux d'engagement des employés de votre organisation avec Vantage Pulse.",
  "Vantage Fit is an employee wellness platform that enables organizations to nurture a healthy workforce by enabling a gamified wellness experience. Meet your workforce's wellness needs through team events and incentivizing health achievements with redeemable points with Vantage Fit.":
    "Vantage Fit est une plateforme de bien-être des employés qui permet aux organisations de développer une main-d'œuvre en bonne santé en permettant une expérience de bien-être ludique. Répondez aux besoins de bien-être de votre main-d'œuvre grâce à des événements d'équipe et à des réalisations incitatives en matière de santé avec des points échangeables avec Vantage Fit.",
  "BENEFITS": "AVANTAGES",
  "Why Vantage Circle?": "Pourquoi Vantage Circle?",
  "Explore all the possibilities to give your employees more reasons to become more engaged.":
    "Explorez toutes les possibilités pour donner à vos employés plus de raisons de s'engager davantage.",
  "Flexible<br/> Rewarding": "Récompenses flexibles",
  "Peer-to-peer<br/> Recognition": "Reconnaissance entre pairs",
  "Employee<br/> Incentivization": "Incitatif des employés",
  "Perks and<br/> Privileges": "Avantages et privilèges",
  "Employee<br/> Well-being": "Bien-être des employés",
  "Employee<br/> Empowerment": "Autonomisation des employés",
  "In the Press": "Dans la Presse",
  "Awards and Brand Mentions": "Récompenses et mentions de marque",
  "Here's a look at some of our achievements over the years and mentions we got from market leaders.":
    "Voici un aperçu de certaines de nos réalisations au fil des ans et des mentions que nous avons reçues des leaders sur le marché.",

  "We are changing the way HR manages Employee Engagement and Benefits programmes.":
    "Nous changeons la façon dont les RH gèrent l'engagement des employés et les programmes d'avantages sociaux.",
  "In 2010, while discussing startup ideas, friends Anjan Pathak and Partha Neog stumbled upon the concept of employee engagement programmes":
    "En 2010, tandis qu'ils discutaient d'idées de start-ups, les deux amis Anjan Pathak et Partha Neog sont tombés sur le concept de programmes d'engagement des employés",
  "Read more": "En savoir plus",
  "Nine startups with promise": "Neuf start-up prometteuses",
  "With 90 percent of its workforce based out of the scenic river town of Guwahati, the seven-year-old startup has kept its operations":
    "Avec 90 % de son personnel basé dans la pittoresque ville fluviale de Guwahati, cette startup de sept ans a maintenu ses opérations",
  "Vantage Circle: Recognize Employees with Gift Cards, Discounts, and Other Perks":
    "Reconnaissez les employés avec des cartes-cadeaux, des remises et d'autres avantages.",
  "Maintaining satisfied and happy employees may build good karma, but it’s also a good business strategy. High turnover rates cost corporations significant sums of money in seeking, onboarding":
    "Garder des employés satisfaits et heureux peut créer un bon karma, mais c'est aussi une bonne stratégie commerciale. Les taux de rotation élevés coûtent aux entreprises des sommes importantes en termes de recherche, d'intégration",
  "Explore More": "Explore plus",

  "Global Capabilities": "Capacités mondiales",
  "Grow with Us": "Grandir avec nous",
  "Associated Global Brands": "Marques mondiales associées",
  "Reward Options": "Options de récompenses",
  "Countries": "Pays",
  "Companies": "Entreprises",
  "Corporate Employees": "d'employés de l'entreprise",
  "Free Resources": "Ressources Gratuites",
  "Our Resource Library Is Absolutely Free of Cost!":
    "Notre bibliothèque de ressources est absolument gratuite!",
  "Keep yourself updated with our latest HR blogs, webinars, guides and ebooks, podcasts, and more.":
    "Tenez-vous au courant de nos derniers blogs RH, webinaires, guides et livres électroniques, podcasts et plus encore.",
  "Blog": "Blog",
  "Insightful articles, best practices, and trends in HR innovation.":
    "Articles perspicaces, meilleures pratiques et tendances en innovation RH.",
  "Read Now": "Lisez maintenant",
  "Free eBooks": "Livres électroniques gratuits",
  "A free resources center with practical guides on HR management.":
    "Un centre de ressources gratuit avec des guides pratiques sur la gestion des RH",
  "Download Now": "Téléchargez maintenant",
  "Influencer Podcast": "Podcasts d’influencers",
  "Listen to thought leaders on best HR practices and trends.":
    "Écoutez les leaders d'opinion sur les meilleures pratiques et tendances en matière de RH.",
  "Listen Now": "Écoutez maintenant",
  "Webinar": "Webinaire",
  "Watch our webinar series to get practical lessons on employee engagement strategies.":
    "Regardez notre série de webinaires pour obtenir des leçons pratiques sur les stratégies d'engagement des employés.",
  "Watch Now": "Regardez maintenant",
  "Want to Know More About Vantage Circle?":
    "Vous voulez en savoir plus sur Vantage Circle?",
  "Contact Us": "Nous contacter",
  "Talk to Us": "Parlez-en avec nous",

  "Compare packages": "Comparer les paquets",
  "Rewards & Recognition": "Récompenses et reconnaissance",
  "Redemption": "Rédemption",
  "Analytics & Reporting": "Analyse et rapports",
  "Social Integrations": "Intégration sociale",
  "Monetary awards": "Prix monétaires",
  "HRMS/HRIS Integrations": "Intégrations HRMS/HRIS",
  "SSO Integration": "Intégration de la SSO",
  "Branding": "Marques",
  "Data & Security": "Données et sécurité",
  "Utility": "Utilitaire",
  "Support": "Appui",
  "Consultation": "Consultation",
  "AI Capabilities": "Capacités d'IA",
  "Instant non-monetary recognition": "Reconnaissance instantanée non monétaire",
  "Automated greetings": "Messages de bienvenue automatisés",
  "Social recognition feed": "Nourriture de reconnaissance sociale",
  "Wall of fame": "Mur de la renommée",
  "Gamified leaderboard": "Tableau de bord Gammé",
  "Team awarding": "Attribution par l'équipe",
  "Panel voting awards": "Récompenses par jury",
  "Employee nudges": "Les nudges des employés",
  "Configurable workflow": "Déroulement configurable",
  "LinkedIn sharing": "Partage de LinkedIn",
  "Long service awards": "Prix de longue durée",
  "Service yearbook": "Annuaire des services",
  "Amazon store": "Magasin d'Amazon",
  "Experiences & holidays": "Expériences et vacances",
  "Gift vouchers": "Bons-cadeaux",
  "Merchandise": "Marchandise",
  "Multi purpose wallet": "Portefeuille à usages multiples",
  "Languages": "Langues",
  "SOLI matrix currency conversion": "Conversion des monnaies de la matrice SOLI",
  "Multi currency budget": "Budget multi monnaie",
  "Native currency redemption": "Rédemption de devises autochtones",
  "Company wide announcements": "Annonces à l'échelle de l'entreprise",
  "HR dashboard": "Tableau de bord HR",
  "Manager dashboard": "Manager dashboard",
  "Dashboard Insights": "Dashboard Insights",
  "Manager monthly summary": "Manager monthly summary",
  "Employee adoption analytics": "Analyse de l'adoption par les employés",
  "Team adoption analytics": "Analyse de l'adoption par l'équipe",
  "Corporate branding": "Marque d'entreprise",
  "Registration landing page": "Page d'accueil de l'enregistrement",
  "Budget Management": "Gestion budgétaire",
  "Team Awarding": "Prix de l'équipe",
  "Delegation": "Délégation",
  "Dedicated account manager": "Gestionnaire de compte spécialisé",
  "User e-mail/chat support": "Support e-mail/chat de l'utilisateur",
  "Award Recommendations": "recommandation de récompense",
  "Smart Compose": "Compose intelligent",
  "Included Products": "Produits inclus",
  "Cashback offers": "Offres de cashback",
  "Classified": "Classée",
  "Custom offers": "Offres personnalisées",
  "Exclusive offers": "Offres exclusives",
  "Add On Products": "Produits additionnels",
  "Steps Challenges": "Steps Challenges",
  "Multi-activity Challenges": "Défis multi-activités",
  "Team Challenges": "Défis d'équipe",
  "Journey Challenges": "Défis liés au voyage",
  "Physical Activity Tracking": "Suivi de l'activité physique",
  "Nutrition Tracking": "Suivi de la nutrition",
  "Mental Wellbeing": "Bien-être mental",
  "HR Admin Dashboard": "HR Admin Dashboard",
  "Wellness Incentives and Rewards": "Incitatifs et récompenses pour le mieux-être",
  "Global Redemption Catalog": "Global Redemption Catalog",
  "Gamified Challenges": "Défis ludifiés",
  "Real-time Leaderboard": "Tableau de classement en temps réel",
  "SSO login and HRMS Integrations": "Login SSO et intégrations au SGRH",
  "Wellness Library": "Bibliothèque du bien-être",
  "Partner Offerings": "Offres de partenaires",
  "Anonymous feedback": "Commentaires anonymes",
  "Employee Experience Insights": "Perspectives d'expérience des employés",
  "Lifecycle Experience Insights": "Perspectives d'expérience du cycle de vie",
  "eNPS survey": "Enquête eNPS",
  "Standard Questions": "Questions types",
  "Unlimited Questions": "Questions illimitées",
  "Heatmap analysis": "Analyse de la carte thermique",
  "Industry benchmarking": "Analyse comparative de l'industrie",
  "Winner of top global awards in Employee Recognition and Engagement": "Lauréat des principaux prix mondiaux en Reconnaissance et Engagement des Employés",
  "companies, from SMEs to Fortune 500, trust us!": "entreprises, des PME aux Fortune 500, nous font confiance !",
  "offers": "offres",
  "Included Products": "Produits inclus",
   "Add On Products": "Produits additionnels",
   "Our global capabilities": "Nos capacités mondiales",
   "Reward Options": "options de récompenses",
   "Languages": "langues",
   "Companies": "entreprises",
   "Users": "millions d'utilisateurs",
   "Delivering simple yet effective employee engagement solutions globally.": "Offrir des solutions d'engagement des employés simples mais efficaces à l'échelle mondiale.",
   "See all clients": "Voir plus de clients"
}

export const fr = {
  ...additionalTrans,
  ...common,
  ...footer,
  ...header,
  ...TestimonialFrench
}

export const de_header = {
    "Download Now": "Jetzt downloaden",
    "Schedule a Free Demo": "kostenlose Demo buchen",
    "Watch Video": "Ver vídeo",
    "Home": "Heim",
    "About us": "Uber uns",
    "Contact us": "Kontaktiere Uns",
    "Download app": "Lade App herunter",
    "Sign in": "Einloggen",
    "Solutions": "Lösungen",
    "Pricing": "Preisgestaltung",
    "Clients": "Kunden",
    "Partners": "Partner",
    "Resources": "Ressourcen",
    "Vantage Rewards": "Vantage Rewards",
    "Employee Rewards and Recognition.": "Mitarbeiter Belohnungen und Erkennung",
    "Vantage Perks": "Vantage Perks",
    "Employee Discount and Benefits.": "Rabatte und Vorteile für Mitarbeiter ",
    "Vantage Pulse": "Vantage Pulse",
    "Employee Survey and Feedback.": "Mitarbeiter Befragung und Feedback",
    "Vantage Fit": "Vantage Fit",
    "Employee Health and Wellness.": "Gesundheit und Wohlbefinden der Mitarbeiter ",
    "All-in-One": "All-in-One",
    "Boost employee engagement with our AI-powered solutions.": "Kurbeln Sie Mitarbeiter Engagement mit unseren von KI- angetriebenen Lösungen an",
    "Integrations": "Integrations",
    "Seamless integrations with your existing HCM/HRIS platform.": "Nahtlose Integration mit ihren bestehenden HCM/HRIS Plattformen",

    "Watch Product Videos": "Schauen Sie Produktvideos an",
    "All-in-One Solution": "All-in-One Lösungen",
    "Employee Recognition Platform": "Mitarbeiter Erkennung Plattform",
    "Employee Pulse Survey": "Mitarbeiter Pulse Befragung",
    "Corporate Wellness Platform": "Corporate Wellness Plattform",
    "Employee Benefits Platform": "Mitarbeiter Prämien Plattform ",

    "Clients Overview": "Kunden",
    "More than 400+ companies are enhancing their employee experience and building an engaged and happy workforce with us.": "Mehr als 400+ Unternehmen verbessern mit uns ihre Mitarbeitererfahrung und bauen eine engagierte und zufriedene Belegschaft auf"
    
}

export const TestimonialFrench = {
    "We are very happy to be associated with InfyGold+, a part of Rewards and Recognition Program at Infosys, and we run this program with Vantage Circle. It has been awesome to have worked with Vantage Circle and the team has helped us provide great experiences to our employees through a single platform, which is easy to use, provides a gamut of options and most importantly, is available on personal smartphones through the app."
    :"Nous sommes très heureux d'être associés à InfyGold+, une partie du Programme de Récompenses et Reconnaissance chez Infosys, et nous gérons ce programme avec Vantage Circle. Il a été formidable de travailler avec Vantage Circle et l'équipe nous a aidés à offrir d'excellentes expériences à nos employés grâce à une plateforme unique, facile à utiliser, offrant une gamme variée d'options et, surtout, disponible sur les smartphones personnels via l'application.",

    "We really like the way that Vantage Circle has created a wave of family spirit within Adecco and the way the platform serves all our colleagues across the branches and it gets everyone together. The rewards points given to our employees and they are free to choose wherever they want to spend their points, which is a big win for us. Vantage Circle has done a great job for Adecco and I would definitely recommend it for other companies as well."
    :"Nous apprécions vraiment la manière dont Vantage Circle a créé un esprit de famille au sein d'Adecco et la façon dont la plateforme sert tous nos collègues à travers les succursales, rassemblant tout le monde. Les points de récompense donnés à nos employés leur permettent de choisir librement où dépenser leurs points, ce qui représente une grande victoire pour nous. Vantage Circle a fait un excellent travail pour Adecco et je le recommanderais certainement à d'autres entreprises également.",

    "Vantage Rewards have been a part of our renewed efforts to establish a culture of recognition by giving our workforce the flexibility to choose their own rewards. The Instant recognition feature makes it convenient for us to reward good work immediately. From looking forward to being incentivized, having the choice of multiple reward options, and the user-friendly interface; has caused our people to get more motivated than ever."
    :"Vantage Rewards a été une partie intégrante de nos efforts renouvelés pour établir une culture de reconnaissance en donnant à notre main-d'œuvre la flexibilité de choisir leurs propres récompenses. La fonctionnalité de reconnaissance instantanée nous rend facile de récompenser immédiatement le bon travail. Le fait d'attendre avec impatience d'être incités, d'avoir le choix entre plusieurs options de récompenses, et l'interface utilisateur conviviale; tout cela a motivé nos employés plus que jamais.",

    "The Rewards and Recognition program we had before was very different from what Vantage Circle offers. After Vantage Circle, everything became a lot easier and structured. When we launched the program we saw an engagement of 55-65% which is quite a good according to me. Right now our plan is to improve our internal communication around it. The most popular feature here is the badges, people are really enjoying giving each other badges."
    :"Le programme de Récompenses et Reconnaissance que nous avions auparavant était très différent de ce que propose Vantage Circle. Après Vantage Circle, tout est devenu beaucoup plus facile et structuré. Lorsque nous avons lancé le programme, nous avons observé un taux d'engagement de 55 à 65 %, ce qui est selon moi assez bon. Actuellement, notre plan est d'améliorer notre communication interne à ce sujet. La fonctionnalité la plus populaire ici est celle des badges ; les gens apprécient vraiment de se donner mutuellement des badges.",

    "Vantage Rewards is the first online-based rewards and recognition platform that we’ve implemented. The point-based rewarding system has made tracking of rewards and it’s data more manageable. The report feature has been helpful in understanding the budget and reward allocations. It has also given us the option of analyzing where we are in terms of recognizing our employees. Every organization, like ours, would certainly benefit from using the platform."
    :"Vantage Rewards est la première plateforme de récompenses et de reconnaissance en ligne que nous avons mise en œuvre. Le système de récompense basé sur les points a rendu le suivi des récompenses et de leurs données plus gérable. La fonctionnalité de rapport a été utile pour comprendre le budget et l'allocation des récompenses. Elle nous a également donné la possibilité d'analyser où nous en sommes en termes de reconnaissance de nos employés. Chaque organisation, comme la nôtre, bénéficierait certainement de l'utilisation de cette plateforme.",

    "Wipro Advantage (powered by Vantage Circle) was launched last year to provide exclusive privileges to our employees pan India. The feedback received from the employees has been very positive and we feel it is an amazing value proposition for our employees."
    :"Wipro Advantage (alimenté par Vantage Circle) a été lancé l'année dernière pour offrir des privilèges exclusifs à nos employés à travers l'Inde. Les retours des employés ont été très positifs et nous pensons que c'est une proposition de valeur incroyable pour nos employés.",

    "Vantage Circle has transformed our previous manual rewarding process. With the ease of use, streamlined rewards distribution, and flexibility of choosing rewards, our TAT time has reduced considerably. Vantage Circle’s admin dashboard gives us incredible insights into data-backed engagement patterns. We have seen a chain reaction of enhanced work performance in all our teams. It really is the perfect solution to hassle-free rewards and recognition."
    :"Vantage Circle a transformé notre précédent processus de récompense manuel. Grâce à la facilité d'utilisation, à la distribution rationalisée des récompenses et à la flexibilité du choix des récompenses, notre temps de traitement a été considérablement réduit. Le tableau de bord administratif de Vantage Circle nous offre des insights incroyables sur les modèles d'engagement soutenus par des données. Nous avons constaté une réaction en chaîne d'amélioration des performances de travail dans toutes nos équipes. C'est vraiment la solution parfaite pour des récompenses et une reconnaissance sans tracas.",

    "Vantage Circle has made our Rewarding and Recognition process seamless, and we are now empowered to instantly recognize and celebrate great work. Giving the employees monetary awards or acknowledging other internal accomplishments has never been easier. The point-based reward gives employees the flexibility to choose their gift cards from various online/retail stores. It encourages employee motivation by showcasing their achievements of milestones and recognitions via social leaderboard on the platform. It also helps us build employee satisfaction in the long run and a culture where recognition is never delayed."
    :"Vantage Circle a rendu notre processus de Récompenses et Reconnaissance fluide, et nous sommes désormais en mesure de reconnaître et de célébrer instantanément l'excellent travail. Donner des récompenses monétaires aux employés ou reconnaître d'autres réalisations internes n'a jamais été aussi facile. La récompense basée sur les points donne aux employés la flexibilité de choisir leurs cartes-cadeaux parmi divers magasins en ligne ou de détail. Cela encourage la motivation des employés en mettant en avant leurs réalisations de jalons et reconnaissances via un tableau de classement social sur la plateforme. Cela nous aide également à construire la satisfaction des employés sur le long terme et une culture où la reconnaissance n'est jamais retardée.",

    "I got to know about the Vantage Circle platform from various colleagues who are working with different companies. Vantage Rewards platform helped us route our Rewards and Recognition (R&R) and also helped in tracking our R&R budget allocations. The most amazing feature provided by VC is the points to money variant, i.e 1 point= Rs.1. Moreover, the manager points are really going smoothly and we just wanted this nomination process to be incorporated by the portal. I will definitely recommend the Vantage Rewards platform to other organizations."
    :"J'ai découvert la plateforme Vantage Circle grâce à divers collègues travaillant dans différentes entreprises. La plateforme Vantage Rewards nous a aidés à organiser nos Récompenses et Reconnaissances (R&R) et également à suivre nos allocations budgétaires R&R. La fonctionnalité la plus étonnante fournie par VC est la variante points en argent, c'est-à-dire 1 point = 1 Rs. De plus, les points des managers fonctionnent vraiment bien et nous souhaitions juste que ce processus de nomination soit intégré par le portail. Je recommanderai définitivement la plateforme Vantage Rewards à d'autres organisations.",

    "Vantage Circle has helped us revamp our Rewards process entirely and gave us the freedom to choose from various options available online as per the individual specific needs. The online platform has helped boost morale for the employees as part of Escalon Family even during COVID-19. It would not have been possible without such a platform when the employees were scattered across the country. It helped us big time and has been our preferred option for all our employee motivation activities. We are glad to be associated with Vantage Circle and look forward to a long-term partnership with them."
    :"Vantage Circle nous a aidés à révolutionner entièrement notre processus de récompenses et nous a donné la liberté de choisir parmi diverses options disponibles en ligne selon les besoins spécifiques de chaque individu. La plateforme en ligne a contribué à renforcer le moral des employés faisant partie de la famille Escalon, même pendant la COVID-19. Cela n'aurait pas été possible sans une telle plateforme alors que les employés étaient dispersés à travers le pays. Elle nous a beaucoup aidés et est devenue notre option préférée pour toutes nos activités de motivation des employés. Nous sommes heureux d'être associés à Vantage Circle et nous attendons avec impatience un partenariat à long terme avec eux.",

    "Vantage Circle Platform was implemented to support existing Reward & Recognition Process. Vantage Reward Points can be availed easily by Employees providing complete freedom to choose from various bouquet of Services and Online options available as per the individual choices (have personalized experience). The online platform made it easier to have instant gratification possible. This Platform is now widely used across all the Functions / Locations. Reward Points are also being given for any Competitions or driving New Initiatives with Spot Prizes given to the Winners. Vantage Reward Points helped in boosting Employees morale even during the pandemic situation. We look forward to a long term partnership with Vantage Circle exploring other features to further enhance R&R Function at Tata Power."
    :"La plateforme Vantage Circle a été mise en place pour soutenir le processus existant de Récompenses et Reconnaissances. Les points de récompense Vantage peuvent être facilement utilisés par les employés, offrant une liberté totale de choisir parmi un large éventail de services et d'options en ligne disponibles selon les choix individuels (expérience personnalisée). La plateforme en ligne a facilité la gratification instantanée. Cette plateforme est désormais largement utilisée dans toutes les Fonctions / Localisations. Des points de récompense sont également attribués pour toute compétition ou pour la promotion de Nouvelles Initiatives, avec des prix immédiats remis aux gagnants. Les points de récompense Vantage ont aidé à booster le moral des employés même pendant la situation pandémique. Nous envisageons un partenariat à long terme avec Vantage Circle pour explorer d'autres fonctionnalités afin d'améliorer davantage la fonction R&R chez Tata Power.",

    "Building a highly engaged workforce is integral to Tata Motors' vision. One of the important pillars for fostering engagement is to celebrate contributions and milestones. This can be best achieved by real-time recognition and celebration. Vantage Circle has been a wonderful partner as we developed our Digital Rewards & Recognition program - Accolades. We value our collaboration with Team Vantage and look forward to working together on offering great experience to our employees."
    :"Construire une main-d'œuvre hautement engagée est essentiel pour la vision de Tata Motors. Un des piliers importants pour favoriser l'engagement est de célébrer les contributions et les étapes clés. Cela peut être mieux réalisé par une reconnaissance et une célébration en temps réel. Vantage Circle a été un partenaire formidable alors que nous développions notre programme numérique de Récompenses et Reconnaissances - Accolades. Nous apprécions notre collaboration avec l'équipe Vantage et sommes impatients de travailler ensemble pour offrir une excellente expérience à nos employés.",

    "Implementing Vantage Perks as our employee benefits platform has been a game-changer for our organization. With exclusive offers from leading brands and cashback points on purchases, our employees have found it effortless to utilize the service provided. Moreover, the chosen employee benefits have been customized as per our requirements. Our employees absolutely love it, making them happier and more engaged. We couldn't be more glad for our association with Vantage Perks. I would highly recommend Vantage Perks as it offers a great redemption method and features products aligned with our employees' interests. Taking part in this initiative encourages employees to contribute more to the workplace."
    :"L'implémentation de Vantage Perks comme plateforme d'avantages pour nos employés a été un véritable tournant pour notre organisation. Avec des offres exclusives de marques de premier plan et des points de remboursement sur les achats, nos employés ont trouvé le service facile à utiliser. De plus, les avantages choisis pour les employés ont été personnalisés selon nos besoins. Nos employés adorent cela, ce qui les rend plus heureux et plus engagés. Nous ne pourrions être plus heureux de notre association avec Vantage Perks. Je recommanderais vivement Vantage Perks car il offre une excellente méthode de rédemption et propose des produits alignés sur les intérêts de nos employés. Participer à cette initiative encourage les employés à contribuer davantage au lieu de travail.",

    "We'd like to express our satisfaction with the seamless implementation of Vantage Circle's Recognition Platform. Working with Vantage Circle's team has been an absolute breeze, making it one of the smoothest implementations we've ever undertaken. From day one, their team has been incredibly supportive, addressing all our inquiries and providing guidance at every step of the journey. We met our project timeline and had everything running within just one month. The entire process was incredibly smooth and hassle-free. It was truly an amazing experience!"
    :"Nous souhaitons exprimer notre satisfaction quant à l'implémentation sans faille de la plateforme de Reconnaissance de Vantage Circle. Travailler avec l'équipe de Vantage Circle a été un véritable plaisir, rendant cela l'une des implémentations les plus fluides que nous ayons jamais entreprises. Dès le premier jour, leur équipe a été incroyablement supportive, répondant à toutes nos demandes et fournissant des conseils à chaque étape du parcours. Nous avons respecté notre calendrier de projet et tout était opérationnel en seulement un mois. Tout le processus a été incroyablement fluide et sans problème. Ce fut vraiment une expérience incroyable !",

    "The need for digitalization, real-time recognition, and access to a wide range of vouchers drove our search for a rewards and recognition platform like Vantage Rewards. What set the platform apart were its customization options, the points validity feature, the user-friendly interface, and the innovative use of hashtags. Our objectives with Vantage Rewards include enhancing employee participation and leveraging analytics for insights. The implementation process has been exceptionally smooth, thanks to great support from the Vantage team, who helped tailor the platform to our specific needs. Their support team was impressively responsive and quick to address any challenges. Based on our experience, we wholeheartedly recommend Vantage Rewards to other organizations. Its vast features, adaptable user interface, and flexibility to meet unique business needs make it an invaluable tool for organizations in a similar position."
    :"Le besoin de digitalisation, de reconnaissance en temps réel et l'accès à une large gamme de bons d'achat ont orienté notre recherche vers une plateforme de récompenses et de reconnaissance comme Vantage Rewards. Ce qui distinguait cette plateforme, c'étaient ses options de personnalisation, la validité des points, l'interface conviviale, et l'utilisation innovante des hashtags. Nos objectifs avec Vantage Rewards incluent l'amélioration de la participation des employés et l'exploitation des analyses pour obtenir des insights. Le processus d'implémentation a été exceptionnellement fluide, grâce à l'excellent soutien de l'équipe Vantage, qui a aidé à adapter la plateforme à nos besoins spécifiques. Leur équipe de support était remarquablement réactive et rapide à résoudre les défis. Sur la base de notre expérience, nous recommandons chaleureusement Vantage Rewards à d'autres organisations. Ses vastes fonctionnalités, son interface utilisateur adaptable, et sa flexibilité pour répondre aux besoins commerciaux uniques en font un outil inestimable pour les organisations dans une situation similaire.",

    "Seeking a flexible R&R platform accommodating our diverse workforce, Vantage Rewards emerged as the ideal choice. Its user-friendly interface, customizable features, and array of face-value reward options surpassed competitors. With the goal of enhancing engagement and productivity, Vantage Rewards seamlessly integrated despite minor site-specific challenges. The support team’s promptness and expertise were commendable. We anticipate heightened morale, improved team cohesion, and enhanced KPIs in engagement and performance. Its alignment with our culture makes it recommended for organizations seeking a robust R&R solution."
    : "En recherchant une plateforme de R&R flexible pour notre main-d'œuvre diverse, Vantage Rewards s'est imposée comme le choix idéal. Son interface conviviale, ses fonctionnalités personnalisables, et son éventail d'options de récompenses à valeur nominale ont surpassé les concurrents. Avec pour objectif d'améliorer l'engagement et la productivité, Vantage Rewards s'est intégrée sans problème malgré de petits défis spécifiques au site. La promptitude et l'expertise de l'équipe de support étaient louables. Nous anticipons une amélioration du moral, une cohésion d'équipe accrue, et une amélioration des KPIs en termes d'engagement et de performance. Son alignement avec notre culture en fait une solution recommandée pour les organisations en quête d'une robuste solution de R&R.",

    "Vantage Circle has been great for us. They met our expectations in every aspect. The pulse tool, a unique feature of their platform, was a massive advantage, and their ability to customize the tool to our needs was exceptional. We appreciated that their updates didn't disrupt our customizations. The sales team was outstanding, mapping out precisely how the tool would benefit our recognition program and enhance our company culture. The implementation process was seamless, thanks to their upfront communication and dedication. Within just 90 days of signing the contract, we were up and running. Vantage Circle's commitment to our success was a pleasant surprise, and their quick response to our customization needs was impressive. It has truly been a transformative experience for our company, embraced by every generation within our organization."
    :"Vantage Circle a été formidable pour nous. Ils ont répondu à nos attentes sous tous les aspects. L'outil Pulse, une caractéristique unique de leur plateforme, a été un avantage considérable, et leur capacité à personnaliser l'outil selon nos besoins était exceptionnelle. Nous avons apprécié que leurs mises à jour n'affectent pas nos personnalisations. L'équipe de vente était excellente, précisant exactement comment l'outil bénéficierait à notre programme de reconnaissance et améliorerait notre culture d'entreprise. Le processus d'implémentation a été fluide, grâce à leur communication ouverte et à leur dévouement. En seulement 90 jours après la signature du contrat, nous étions opérationnels. L'engagement de Vantage Circle à notre succès a été une agréable surprise, et leur réponse rapide à nos besoins de personnalisation a été impressionnante. Ce fut véritablement une expérience transformatrice pour notre entreprise, adoptée par chaque génération au sein de notre organisation.",

    "The experience with the Vantage Circle team has been exceptional. The implementation process proceeded seamlessly, adhering to our agreed-upon timelines. We greatly appreciated the multilingual support provided, which facilitated the entire implementation journey. The Vantage Circle Customer Success team has consistently demonstrated great support and responsiveness. The Customer Success team is always available to help and responds to our requests as quickly as possible. We are very grateful to everyone who has collaborated in our launch and for their support throughout the process. Thanks Vantage, for being a platform that meets our needs. Vantage complies with the support and incorporates two languages and two currencies at the same time. This support covers our population in Puerto Rico and Colombia. Since the launch, we have witnessed a noteworthy increase in employee engagement. Vantage Circle's product and its features are intuitive, making it effortless for our team to grasp and implement. Thanks to Vantage Circle's product, we now feel positioned to enhance our employee experience significantly. We are happy with Vantage Circle team and platform."
    :"L'expérience avec l'équipe Vantage Circle a été exceptionnelle. Le processus d'implémentation s'est déroulé sans heurt, en respectant nos délais convenus. Nous avons grandement apprécié le support multilingue fourni, qui a facilité tout le parcours d'implémentation. L'équipe de succès client de Vantage Circle a constamment démontré un grand soutien et une grande réactivité. L'équipe de succès client est toujours disponible pour aider et répond à nos demandes aussi rapidement que possible. Nous sommes très reconnaissants envers tous ceux qui ont collaboré à notre lancement et pour leur soutien tout au long du processus. Merci Vantage, d'être une plateforme qui répond à nos besoins. Vantage se conforme au support et intègre deux langues et deux devises en même temps. Ce support couvre notre population à Porto Rico et en Colombie. Depuis le lancement, nous avons constaté une augmentation notable de l'engagement des employés. Le produit et ses fonctionnalités de Vantage Circle sont intuitifs, rendant son adoption et son implémentation sans effort pour notre équipe. Grâce au produit de Vantage Circle, nous nous sentons désormais en position d'améliorer significativement l'expérience de nos employés. Nous sommes satisfaits de l'équipe et de la plateforme Vantage Circle.",

    "Vantage R&R revolutionized recognition at VFI-SLK. While our prior methods were decent, their automation and diverse rewards transformed our approach. Before, we had limited options for recognizing employees, but the current automated system provides various categories for rewarding the team. Implementing Vantage R&R has proven to be useful and seamlessly integrated into our workflow, making the entire process effortless. Although I'm still exploring features, the initial experience is promising. While specific metrics aren't quantified, the potential for positive impact is evident. I wholeheartedly recommend Vantage R&R for its user-friendly, efficient, and diverse staff recognition and rewards approach."
    :"Vantage R&R a révolutionné la reconnaissance chez VFI-SLK. Bien que nos méthodes précédentes étaient correctes, leur automatisation et leurs diverses récompenses ont transformé notre approche. Auparavant, nous avions des options limitées pour reconnaître les employés, mais le système automatisé actuel offre diverses catégories pour récompenser l'équipe. L'implémentation de Vantage R&R s'est avérée utile et s'est intégrée sans problème dans notre flux de travail, rendant tout le processus sans effort. Bien que j'explore encore les fonctionnalités, l'expérience initiale est prometteuse. Bien que des métriques spécifiques ne soient pas quantifiées, le potentiel d'impact positif est évident. Je recommande de tout cœur Vantage R&R pour son approche conviviale, efficace, et diversifiée de reconnaissance et de récompense du personnel.",

    
    "AVP and Head of Internal Communication": "Vice-président adjoint et Responsable de la Communication Interne.",
    "Human Resources": "Ressources Humaines",
    "HR, Employee Experience & Internal Communications": "RH, Expérience des Employés & Communications Internes",
    "Internal Communications Manager": "Responsable des Communications Internes",
    "Employee Relations": "Relations avec les Employés",
    "Vice President and HR Head": "Vice-président et Responsable RH",
    "HR Business Partner": "Partenaire d'Affaires RH",
    "Associate Manager": "Responsable Associé",
    "Associate Manager- HR, Voya India (Formerly VFI SLK)": "Responsable Associé - RH, Voya India (anciennement VFI SLK)",
    "Lead HR": "Responsable RH principal",
    "Head – R&R & Quality Improvement": "Responsable – R&R et Amélioration de la Qualité",
    "Head – Performance Management, Compensation & Benefits": "Responsable – Gestion de la Performance, Rémunération et Avantages",
    "HR Executive": "Exécutif RH",
    "VP Human Resources, Transformation Projects": "VP Ressources Humaines, Projets de Transformation",
    "Manager HR": "Responsable des ressources humaines",
    " Regional HR Advisor, South Asia, People & Talent": "Conseiller RH Régional, Asie du Sud, Personnes & Talents",
    "Company Representative": "Représentant de l'entreprise",
    "HR, Voya India (Formerly VFI SLK)": "RH, Voya India (Anciennement VFI SLK)"
}
export const common = {
    "Testimonials": "TÉMOIGNAGES",
    "Our Customers Love Us as Much as We Love Them.<br/> See for Yourself!": "Nos clients nous aiment autant que nous les aimons.<br/> Voir par vous-même!",
    "We are very happy to be associated with InfyGold+, a part of Rewards and Recognition Program at Infosys, and we run this program with Vantage Circle. It has been awesome to have worked with Vantage Circle and the team has helped us provide great experiences to our employees through a single platform, which is easy to use, provides a gamut of options and most importantly, is available on personal smartphones through the app.": "Nous sommes très heureux d'être associés à InfyGold+, une partie du programme de récompenses et de reconnaissance d'Infosys, et nous gérons ce programme avec Vantage Circle. C'est formidable d'avoir travaillé avec Vantage Circle et l'équipe nous a aidé à offrir des expériences formidables à nos employés via une plateforme unique, qui est facile à utiliser, offre une gamme d'options et, surtout, est disponible sur les smartphones personnels via l'application.",
    "AVP and Head of Internal Communication": "VP Assistant et Responsable Communication Interne",
    "Vantage Rewards is the first online-based rewards and recognition platform that we’ve implemented. The point-based rewarding system has made tracking of rewards and it’s data more manageable. The report feature has been helpful in understanding the budget and reward allocations. It has also given us the option of analyzing where we are in terms of recognizing our employees. Every organization, like ours, would certainly benefit from using the platform.": "Vantage Rewards est la première plateforme de récompenses et de reconnaissance en ligne que nous avons mise en place. Le système de récompense par points a rendu le suivi des récompenses et ses données plus facile à gérer. La formule de rapport a été utile pour comprendre le budget et les allocations de récompenses. Cela nous a également donné la possibilité d'analyser où nous en sommes en termes de reconnaissance de nos employés. Chaque organisation, comme la nôtre, gagnerait certainement à utiliser la plateforme.",
    "Manager – Employee Relations": "Manager – Employee Relations",
    "Vantage Rewards have been a part of our renewed efforts to establish a culture of recognition by giving our workforce the flexibility to choose their own rewards. The Instant recognition feature makes it convenient for us to reward good work immediately. From looking forward to being incentivized, having the choice of multiple reward options, and the user-friendly interface; has caused our people to get more motivated than ever.": "Vantage Rewards fait partie de nos efforts renouvelés pour établir une culture de reconnaissance en donnant à nos employés la possibilité de choisir leurs propres récompenses. La fonction de reconnaissance instantanée nous permet de récompenser immédiatement le bon travail. De l'impatience d'être incité, d'avoir le choix entre plusieurs options de récompense et l'interface conviviale ont fait que nos employés sont plus motivés que jamais.",
    "HR - Employee Experience & Internal Communications": "RH - Expérience Employé & Communication Interne",
    "The Rewards and Recognition program we had before was very different from what Vantage Circle offers. After Vantage Circle, everything became a lot easier and structured. When we launched the program we saw an engagement of 55-65% which is quite a good according to me. Right now our plan is to improve our internal communication around it. The most popular feature here is the badges, people are really enjoying giving each other badges.": "Le programme de récompenses et de reconnaissance que nous avions déjà était très différent de ce qu'offre Vantage Circle. Après Vantage Circle, tout est devenu beaucoup plus facile et structuré. Lorsque nous avons lancé le programme, nous avons constaté un engagement de 55 à 65 %, ce qui est assez bon selon moi. Pour l'instant, notre plan consiste à améliorer notre communication interne à ce sujet. La partie la plus populaire est celle des badges. Les gens aiment beaucoup se donner des badges entre eux.",
    "Internal Communications Manager": "Responsable Communication Interne",
    "We really like the way that Vantage Circle has created a wave of family spirit within Adecco and the way the platform serves all our colleagues across the branches and it gets everyone together. The rewards points given to our employees and they are free to choose wherever they want to spend their points, which is a big win for us. Vantage Circle has done a great job for Adecco and I would definitely recommend it for other companies as well.": "Nous apprécions vraiment la façon dont Vantage Circle a créé une vague d'esprit de famille au sein d'Adecco et la façon dont la plateforme sert à tous nos collègues dans toutes les branches et rassemble tout le monde. Les points de récompense sont donnés à nos employés et ils sont libres de choisir où ils veulent dépenser leurs points, ce qui est une grande victoire pour nous. Vantage Circle a fait un excellent travail pour Adecco et je le recommanderais sans hésiter à d'autres entreprises.",
    "Human Resource": "Ressources Humaines",
    "Wipro Advantage (powered by Vantage Circle) was launched last year to provide exclusive privileges to our employees pan India. The feedback received from the employees has been very positive and we feel it is an amazing value proposition for our employees.": "Wipro Advantage (propulsé par Vantage Circle) a été lancé l'année dernière pour offrir des privilèges exclusifs à nos employés en Inde. Les réactions des employés ont été très positives et nous pensons que c'est une proposition de valeur exceptionnelle pour nos employés.",
    "Vice President and HR Head": "Vice-président et chef des RH",
    "Vantage Circle has transformed our previous manual rewarding process. With the ease of use, streamlined rewards distribution, and flexibility of choosing rewards, our TAT time has reduced considerably. Vantage Circle’s admin dashboard gives us incredible insights into data-backed engagement patterns. We have seen a chain reaction of enhanced work performance in all our teams. It really is the perfect solution to hassle-free rewards and recognition.": "Vantage Circle a transformé notre précédent processus de récompense manuel. Grâce à sa facilité d'utilisation, à la rationalisation de la distribution des récompenses et à la flexibilité du choix des récompenses, notre délai d'exécution a considérablement diminué. Le tableau de bord d'administration de Vantage Circle nous donne des informations incroyables sur les modèles d'engagement basés sur des données. Nous avons constaté une réaction en série d'amélioration des performances professionnelles dans toutes nos équipes. C'est vraiment la solution parfaite pour des récompenses et une reconnaissance sans tracas.",
    "HR Business Partner": "Partenaire d'affaires RH",
    "It's been slightly over a year since we launched our rewards and recognition platform in partnership with Vantage circle. FirstReward is a good online platform which enables us to reward employees across the globe, encourage bottom up, top down and peer to peer appreciation. Moreover, the tool has also enabled us to further build and strengthen our existing rewards and appreciation culture. The team has partnered with us at every stage to rollout enhancements where possible.": "Cela fait un peu plus d'un an que nous avons lancé notre plateforme de récompenses et de reconnaissance en partenariat avec Vantage circle. FirstReward est une bonne plateforme en ligne qui nous permet de récompenser les employés à travers le monde, d'encourager l'appréciation ascendante, descendante et entre pairs. En outre, l'outil nous a également permis de développer et de renforcer notre culture existante de récompenses et d'appréciation. L'équipe s'est associée à nous à chaque étape pour apporter des améliorations lorsque cela était possible.",
    "Vantage Circle has made our Rewarding and Recognition process seamless, and we are now empowered to instantly recognize and celebrate great work. Giving the employees monetary awards or acknowledging other internal accomplishments has never been easier. The point-based reward gives employees the flexibility to choose their gift cards from various online/retail stores. It encourages employee motivation by showcasing their achievements of milestones and recognitions via social leaderboard on the platform. It also helps us build employee satisfaction in the long run and a culture where recognition is never delayed.": "Vantage Circle a rendu notre processus de récompense et de reconnaissance transparent, et nous sommes désormais habilités à reconnaître et à célébrer instantanément l'excellent travail. Remettre aux employés des récompenses monétaires ou reconnaître d'autres réalisations internes n'a jamais été aussi facile. La récompense en points donne aux employés la possibilité de choisir leurs cartes-cadeaux parmi différents magasins en ligne/de détail. La récompense en points donne aux employés la possibilité de choisir leurs cartes-cadeaux parmi différents magasins en ligne/de détail. Cela encourage la motivation des employés en présentant leurs réalisations d'étapes et de reconnaissances via le classement social sur la plate-forme. Cela nous permet également de renforcer la satisfaction des employés à long terme et d'instaurer une culture dans laquelle la reconnaissance ne se fait jamais attendre.",
    "Associate Manager": "Associé-Gérant",
    "I got to know about the Vantage Circle platform from various colleagues who are working with different companies. Vantage Rewards platform helped us route our Rewards and Recognition (R&R) and also helped in tracking our R&R budget allocations. The most amazing feature provided by VC is the points to money variant, i.e 1 point= Rs.1. Moreover, the manager points are really going smoothly and we just wanted this nomination process to be incorporated by the portal. I will definitely recommend the Vantage Rewards platform to other organizations.": "J'ai découvert la plate-forme Vantage Circle grâce à divers collègues qui travaillent avec différentes entreprises. La plate-forme Vantage Rewards nous a aidé à acheminer nos récompenses et notre reconnaissance (R&R) et a également aidé à suivre nos allocations budgétaires R&R. La principale caractéristique de VC est la variante de conversion des points en argent, c'est-à-dire 1 point = 1 roupie. De plus, les points de gestion se déroulent vraiment bien et nous voulions simplement que le portail intègre ce processus de nomination. Je recommanderai certainement la plateforme Vantage Rewards à d'autres organisations.",
    "Associate Manager- HR": "Associé-Gérant RH",
    "Vantage Circle has helped us revamp our Rewards process entirely and gave us the freedom to choose from various options available online as per the individual specific needs. The online platform has helped boost morale for the employees as part of Escalon Family even during COVID-19. It would not have been possible without such a platform when the employees were scattered across the country. It helped us big time and has been our preferred option for all our employee motivation activities. We are glad to be associated with Vantage Circle and look forward to a long-term partnership with them.": "Vantage Circle nous a aidé à réorganiser entièrement notre processus de récompenses et nous a donné la liberté de choisir parmi les diverses options disponibles en ligne en fonction des besoins spécifiques de chacun. La plate-forme en ligne a contribué à remonter le moral des employés dans le cadre de la famille Escalon, même pendant COVID-19. Cela n'aurait pas été possible sans une telle plateforme lorsque les employés étaient dispersés dans tout le pays. Elle nous a beaucoup aidés et a été notre option préférée pour toutes nos activités de motivation des employés. Nous sommes heureux d'être associés à Vantage Circle et nous nous réjouissons d'un partenariat à long terme avec eux.",
    "Lead HR": "Chef des RH",
    "Testimonials from our Brand Partners": "Témoignages de nos partenaires de marque",
    "At EarlySalary, we are looking to help young salaried professionals every time they fall short of cash. Our partnership with Vantage Circle has been instrumental in helping us reach 1 lakh loans and is one of the top partners for EarlySalary. Vantage Circle has helped us reach out and engage with a large corporate audience.": "Chez EarlySalary, nous cherchons à aider les jeunes professionnels salariés chaque fois qu'ils manquent d'argent. Notre partenariat avec Vantage Circle nous a permis de réaliser 100 milles prêts et est l'un des meilleurs partenaires pour EarlySalary. Vantage Circle nous a aidés à atteindre et à interagir avec un large public d'entreprises.",
    "Vantage Circle helped us choose and customize offers which helped us grow our business by targeting a trusted network of corporate employees.": "Vantage Circle nous a aidés à choisir et à personnaliser des offres qui nous ont aidés à développer notre activité en ciblant un réseau de confiance des employés d'entreprise.",
    "Regional Head": "Responsable régional",
    "Vantage Circle allowed us to reach out to our ideal audience and build brand awareness and generate consistent sales every month.": "Vantage Circle nous a permis d'atteindre notre public idéal, de renforcer la notoriété de la marque et de générer des ventes régulières chaque mois.",
    "National Brand Head": "Responsable nationale de la marque",
    "Not Found": "Non trouvé",
    "You just hit a route that doesn't exist.": "Tu viens d'emprunter une route qui n'existe pas.",
    "gohome": "Rentrer à la <1>maison</1>  pour recommencer.",
    "Allow Cookies" : "Autoriser les cookies",
    "Decline Cookies" : "Refuser les cookies"
}
export const footer = {
    "Quick Links": "Quick Links",
    "About us": "About us",
    "Careers - We're hiring!": "Careers - We're hiring!",
    "Advertise with us": "Advertise with us",
    "Partner with us": "Partner with us",
    "In the News": "In the News",
    "Download App": "Download App",
    "Contact us": "Contact us",
    "Solutions": "Products",
    "Employee Recognition": "Employee Recognition",
    "Employee Discount": "Employee Discount",
    "Employee Survey": "Employee Survey",
    "Employee Wellness": "Employee Wellness",
    "All-in-One Solution": "All-in-One Solution",
    "Resources Center": "Resources Center",
    "Blog": "Blog",
    "Guides and Ebook": "Guides and Ebook",
    "Case Studies": "Case Studies",
    "Vantage Doers Webinars": "Vantage Doers Webinars",
    "Vantage Podcasts": "Vantage Podcasts",
    "Integration": "Integration",
    "Learn More": "Learn More",
    "Employee Engagement": "Employee Engagement",
    "Rewards & Recognition": "Rewards & Recognition",
    "Employee Experience": "Employee Experience",
    "Pulse Survey": "Pulse Survey",
    "Diversity and Inclusion": "Diversity and Inclusion",
    "Leadership Qualities": "Leadership Qualities",
    "Employee Motivation": "Employee Motivation",
    "Employee Benefits": "Employee Benefits",
    "Work from home Policy": "Work from home Policy",
    "Employee Retention": "Employee Retention",
    "360 Degree Appraisal": "360 Degree Appraisal",
    "Collaborate with us to provide your employees an engaging and rewarding experience.": "Collaborate with us to provide your employees an engaging and rewarding experience.",
    "Download app": "Download app",
    "FREE DOWNLOAD": "FREE DOWNLOAD",
    "Google Play": "Google Play",
    "App Store": "App Store",
    "Locations": "Locations",
    "New York, USA, 199 Jericho Tpke Ste 301, Floral Park, NY 11001-2100": "New York, USA, 199 Jericho Tpke Ste 301, Floral Park, NY 11001-2100",
    "New South Wales, AUS, Suite 1A Level 2, 802-808 Pacific Highway, Gordon NSW – 2072": "New South Wales, AUS, Suite 1A Level 2, 802-808 Pacific Highway, Gordon NSW – 2072",
    "New Delhi, India, C1-1151, Vasant Kunj, New Delhi – 110070": "New Delhi, India, C1-1151, Vasant Kunj, New Delhi – 110070",
    "All rights reserved.": "© Vantage Circle. 2024 All rights reserved.",
    "GDPR": "GDPR",
    "Security": "Security",
    "Terms and Conditions": "Terms and Conditions",
    "Privacy Policy": "Privacy Policy",
    "Cookie Policy" : "Cookie Policy",
    "Image Copyright": "Image Copyright",
    "All rights reserved.": "© Vantage Circle. 2024 All rights reserved.",
    "DPDP" : "DPDP"
}
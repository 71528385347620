import { useEffect, useState } from "react"
import { languagesArr } from "../../static/constants"
import { languageandFlags } from "../../static/constants"

export function useLanguages() {
  const initialState =
    typeof window === "undefined" ? null : window.localStorage.getItem("languageSelected")

  const [currLanguage, setCurrLanguage] = useState()

  const originalPathsObj = languagesArr.reduce((prevVal, currVal) => {
    if (currVal == "en") {
      return {
        ...prevVal,
        [currVal]: `/`,
      }
    } else
      return {
        ...prevVal,
        [currVal]: `/${currVal}/`,
      }
  }, {})
  const changeLanguage = lang => {
    if (typeof window !== undefined) {
      window.localStorage.setItem("languageSelected", `${lang}`)
    }
    setCurrLanguage(lang)
  }

  useEffect(() => {
    if (typeof window !== undefined) {
      const langInLocalStorage = window.localStorage.getItem("languageSelected")
      if (langInLocalStorage == null || langInLocalStorage == undefined) {
        window.localStorage.setItem("languageSelected", "en")
        setCurrLanguage("en")
      } else setCurrLanguage(langInLocalStorage)
    }
  }, [])

  return [
    currLanguage,
    languagesArr,
    languageandFlags,
    originalPathsObj[currLanguage],
    changeLanguage,
  ]
}

import {de_footer} from  './footer'
import {de_header} from  './header'

export const common = {
    "Testimonials": "TESTIMONIALEN",
    "Our Customers Love Us as Much as We Love Them.<br/> See for Yourself!": "Onze klanten houden net zoveel van ons als wij van hen.<br/>Kijk zelf maar!",
    "We are very happy to be associated with InfyGold+, a part of Rewards and Recognition Program at Infosys, and we run this program with Vantage Circle. It has been awesome to have worked with Vantage Circle and the team has helped us provide great experiences to our employees through a single platform, which is easy to use, provides a gamut of options and most importantly, is available on personal smartphones through the app.": "We zijn erg blij om geassocieerd te zijn met InfyGold+, een onderdeel van het Beloningen en erkenningsprogramma bij Infosys, en we voeren dit programma uit met Vantage Cirkel. Het was geweldig om met Vantage Cirkel te hebben samengewerkt en het team heeft ons geholpen om onze medewerkers geweldige ervaringen te bieden via een enkel platform, dat gemakkelijk te gebruiken is, een scala aan opties biedt en, wat nog belangrijker is, beschikbaar is op persoonlijke smartphones via de app.",
    "AVP and Head of Internal Communication": "AVP en Hoofd Interne Communicatie, Infosys",
    "Vantage Rewards is the first online-based rewards and recognition platform that we’ve implemented. The point-based rewarding system has made tracking of rewards and it’s data more manageable. The report feature has been helpful in understanding the budget and reward allocations. It has also given us the option of analyzing where we are in terms of recognizing our employees. Every organization, like ours, would certainly benefit from using the platform.": "Vantage Beloningen is het eerste online belonings- en erkenningsplatform dat wij hebben geïmplementeerd. Het op punten gebaseerde beloningssysteem heeft het bijhouden van beloningen en de gegevens ervan overzichtelijker gemaakt. De rapportagefunctie is nuttig geweest bij het begrijpen van het budget en de beloningstoewijzingen. Het heeft ons ook de mogelijkheid gegeven om te analyseren waar we staan in termen van het erkennen van onze medewerkers. Elke organisatie, zoals de onze, zou zeker baat hebben bij het gebruik van het platform.",
    "Manager – Employee Relations": "Manager- Werknemersrelaties, Merilítica",
    "Vantage Rewards have been a part of our renewed efforts to establish a culture of recognition by giving our workforce the flexibility to choose their own rewards. The Instant recognition feature makes it convenient for us to reward good work immediately. From looking forward to being incentivized, having the choice of multiple reward options, and the user-friendly interface; has caused our people to get more motivated than ever.": "Vantage Beloningen maakt deel uit van onze hernieuwde inspanningen om een cultuur van erkenning tot stand te brengen door onze werknemers de flexibiliteit te geven om hun eigen beloningen te kiezen. De functie Instant recognition maakt het ons gemakkelijk om goed werk onmiddellijk te belonen. De keuze uit meerdere beloningsopties en de gebruiksvriendelijke interface hebben ervoor gezorgd dat onze mensen meer dan ooit gemotiveerd zijn.",
    "HR - Employee Experience & Internal Communications": "HR - Werknemerservaring & Interne communicatie, Arvind",
    "The Rewards and Recognition program we had before was very different from what Vantage Circle offers. After Vantage Circle, everything became a lot easier and structured. When we launched the program we saw an engagement of 55-65% which is quite a good according to me. Right now our plan is to improve our internal communication around it. The most popular feature here is the badges, people are really enjoying giving each other badges.": "Das Prämien- und Anerkennungsprogramm, das wir zuvor hatten, unterschied sich stark von dem, was Vantage Circle anbietet. Nach Vantage Circle wurde alles viel einfacher und strukturierter. Als wir das Programm starteten, sahen wir ein Engagement von 55-65 %, was meiner Meinung nach ziemlich gut ist. Im Moment ist es unser Plan, unsere interne Kommunikation diesbezüglich zu verbessern. Das beliebteste Feature hier sind die Badges, die Leute genießen es wirklich, sich gegenseitig Badges zu geben.",
    "Internal Communications Manager": "Gerente de Comunicação Interna, TATA Realty",
    "We really like the way that Vantage Circle has created a wave of family spirit within Adecco and the way the platform serves all our colleagues across the branches and it gets everyone together. The rewards points given to our employees and they are free to choose wherever they want to spend their points, which is a big win for us. Vantage Circle has done a great job for Adecco and I would definitely recommend it for other companies as well.": "We houden echt van de manier waarop Vantage Cirkel een golf van familiegeest heeft gecreëerd binnen Adecco en de manier waarop het platform al onze collega's in de vestigingen bedient en iedereen samenbrengt. De beloningspunten worden aan onze werknemers gegeven en zij zijn vrij om te kiezen waar zij hun punten willen besteden, wat een grote winst voor ons is. Vantage Cirkel heeft geweldig werk geleverd voor Adecco en ik zou het ook zeker aanbevelen voor andere bedrijven.",
    "Human Resource": "Recursos Humanos",
    "Wipro Advantage (powered by Vantage Circle) was launched last year to provide exclusive privileges to our employees pan India. The feedback received from the employees has been very positive and we feel it is an amazing value proposition for our employees.": "Wipro Voordeel (aangedreven door Vantage Circle) werd vorig jaar gelanceerd om onze werknemers in heel India uitstekende privileges te bieden. De feedback van de werknemers was zeer positief en we zijn van mening dat het een fantastische waardepropositie is voor onze werknemers.",
    "Vice President and HR Head": "Vizepräsident und Personalleiter, Wipro BPS",
    "Vantage Circle has transformed our previous manual rewarding process. With the ease of use, streamlined rewards distribution, and flexibility of choosing rewards, our TAT time has reduced considerably. Vantage Circle’s admin dashboard gives us incredible insights into data-backed engagement patterns. We have seen a chain reaction of enhanced work performance in all our teams. It really is the perfect solution to hassle-free rewards and recognition.": "Vantage Cirkel heeft ons vroeger manueel beloningsproces getransformeerd. Dankzij het gebruiksgemak, de gestroomlijnde uitreiking van beloningen en de flexibiliteit bij het kiezen van beloningen, is onze TAT-tijd aanzienlijk verminderd. Het beheer dashboard van Vantage Cirkel geeft ons ongelooflijke inzichten in gegevens-gebaseerde verloofd patronen. We hebben een kettingreactie gezien van verbeterde werkprestaties in al onze teams. Het is echt de perfecte oplossing voor probleemloze beloningen en erkenning.",
    "HR Business Partner": "HR Business Partner",
    "It's been slightly over a year since we launched our rewards and recognition platform in partnership with Vantage circle. FirstReward is a good online platform which enables us to reward employees across the globe, encourage bottom up, top down and peer to peer appreciation. Moreover, the tool has also enabled us to further build and strengthen our existing rewards and appreciation culture. The team has partnered with us at every stage to rollout enhancements where possible.": "Het is iets meer dan een jaar geleden dat we ons belonings- en erkenningsplatform in samenwerking met Vantage cirkel lanceerden. FirstReward is een goed online platform dat ons in staat stelt medewerkers over de hele wereld te belonen, onderkant boven, peer-te-peer waardering aan te moedigen. Bovendien heeft het instrument ons ook in staat gesteld onze bestaande belonings- en waarderingscultuur verder uit te bouwen en te versterken. Het team heeft in elke fase met ons samengewerkt om waar mogelijk verbeteringen door te voeren.",
    "Vantage Circle has made our Rewarding and Recognition process seamless, and we are now empowered to instantly recognize and celebrate great work. Giving the employees monetary awards or acknowledging other internal accomplishments has never been easier. The point-based reward gives employees the flexibility to choose their gift cards from various online/retail stores. It encourages employee motivation by showcasing their achievements of milestones and recognitions via social leaderboard on the platform. It also helps us build employee satisfaction in the long run and a culture where recognition is never delayed.": "Vantage Cirkel heeft ons belonings- en erkenningsproces naadloos gemaakt, en we zijn nu in staat om direct te herkennen en te vieren geweldig werk. Het was nog nooit zo eenvoudig om de werknemers een geldprijs toe te kennen of andere interne verwezenlijkingen te erkennen. De beloning op basis van punten geeft werknemers de flexibiliteit om hun cadeaubonnen uit verschillende online-/retailwinkels te kiezen. Het stimuleert de motivatie van werknemers door hun prestaties van mijlpalen en erkenningen te laten zien via sociale leaderboard op het platform. Het helpt ons ook om op lange termijn te bouwen aan de tevredenheid van onze werknemers en aan een cultuur waarin erkenning nooit op zich laat wachten.",
    "Associate Manager": "Geassocieerd Manager",
    "I got to know about the Vantage Circle platform from various colleagues who are working with different companies. Vantage Rewards platform helped us route our Rewards and Recognition (R&R) and also helped in tracking our R&R budget allocations. The most amazing feature provided by VC is the points to money variant, i.e 1 point= Rs.1. Moreover, the manager points are really going smoothly and we just wanted this nomination process to be incorporated by the portal. I will definitely recommend the Vantage Rewards platform to other organizations.": "Ik heb het Vantage Circle platform leren kennen van verschillende collega's die bij verschillende bedrijven werken. Het Vantage Rewards platform hielp ons onze Beloningen en erkenning (R&R) te routeren en hielp ons ook bij het opvolgen van onze R&R budget toewijzingen. De meest verbazingwekkende eigenschap die door VC wordt verstrekt is de punten aan geldvariant, d.w.z. 1 punt= Rs.1. Bovendien lopen de managerpunten echt gesmeerd en wij wilden alleen maar dat dit nominatieproces door het portaal zou worden opgenomen. Ik zal het Vantage Beloningen platform zeker aanbevelen aan andere organisaties.",
    "Associate Manager- HR": "-Geassocieerd Manage",
    "Vantage Circle has helped us revamp our Rewards process entirely and gave us the freedom to choose from various options available online as per the individual specific needs. The online platform has helped boost morale for the employees as part of Escalon Family even during COVID-19. It would not have been possible without such a platform when the employees were scattered across the country. It helped us big time and has been our preferred option for all our employee motivation activities. We are glad to be associated with Vantage Circle and look forward to a long-term partnership with them.": "Vantage Circle heeft ons geholpen om ons Beloningen platform volledig te vernieuwen en gaf ons de vrijheid om te kiezen uit verschillende opties die online beschikbaar zijn, afhankelijk van de individuele specifieke behoeften. Het online platform heeft geholpen om het moreel van de werknemers als onderdeel van Escalon Familie te stimuleren, zelfs tijdens COVID-19. Dit zou niet mogelijk zijn geweest zonder een dergelijk platform toen de werknemers over het hele land verspreid waren. Het heeft ons enorm geholpen en is onze voorkeursoptie geworden voor al onze activiteiten om werknemers te motiveren.Wij zijn blij met Vantage Circle geassocieerd te zijn en kijken uit naar een langdurig partnerschap met hen.",
    "Lead HR": "Lead HR, Escalon Bedrijfsdiensten",
    "Not Found": "Nicht Gefunden",
    "You just hit a route that doesn't exist.": "Sie haben gerade eine Route getroffen, die nicht existiert",
    "gohome": "Nach Hause gehen",
    "Allow Cookies" : "Cookies zulassen",
    "Decline Cookies" : "Cookies ablehnen",
    "companies, from SMEs to Fortune 500, trust us!": "Unternehmen, von KMUs bis hin zu Fortune 500, vertrauen uns !",
    "offers": "Angebote",
    "Included Products": "Enthaltene Produkte",
     "Add On Products": "Zusatzprodukte",
    "Winner of top global awards in Employee Recognition and Engagement": "Gewinner von weltweit führenden Auszeichnungen für Mitarbeiteranerkennung und Engagement",
    "Our global capabilities": "Unsere globalen Fähigkeiten",
    "Reward Options": "Belohnungsoptionen",
    "Languages": "Sprachen",
    "Companies": "Unternehmen",
    "Users": "Benutzer",
    "Delivering simple yet effective employee engagement solutions globally.": "Bereitstellung einfacher, aber effektiver Lösungen zur Mitarbeiterbindung weltweit.",
    "See all clients": "Alle Kunden",
    "Countries": "Länder"
}


export const de = {
    ...de_footer,
    ...de_header,
    ...common
}


import React, { useState, useEffect } from "react"
import { useLanguages } from "../hooks/useLanguages"
import { languageObj } from "../../locales/translations/index"

export default function Trans({ children, pageLanguage }) {
  const [currLanguage] = useLanguages()
  const [translations, setTranslations] = useState()
  const [pageTranslations, setPageTranslations] = useState()

  useEffect(() => {
    setTranslations(languageObj[currLanguage])
    setPageTranslations(languageObj[pageLanguage])
  }, [currLanguage, pageLanguage])

  // console.log(pageTranslations[children])

  return (
    <span>
      {pageTranslations == undefined ? translations == undefined ? null : translations[children] : pageTranslations[children]}
    </span>
  )
}

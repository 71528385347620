export const es_header = {
    "Download Now": "Descargar ahora",
    "Schedule a Free Demo": "Solicita una Demo",
    "Watch Video": "Ver vídeo",
    "Home": "Página principal",
    "About us": "Sobre nosotros",
    "Contact us": "Contáctenos",
    "Download app": "Descargar aplicación",
    "Sign in": "Iniciar sesión",
    "Solutions": "Soluciones",
    "Pricing": "Precios",
    "Clients": "Clientes",
    "Partners": "Socios",
    "Resources": "Recursos",
    "Existing customer": "Cliente existente",

    "Vantage Rewards": "Vantage Rewards",
    "Employee Rewards and Recognition.": "Recompensas y reconocimiento a los empleados.",
    "Vantage Perks": "Vantage Perks",
    "Employee Discount and Benefits.": "Beneficios y descuentos para empleados.",
    "Vantage Pulse": "Vantage Pulse",
    "Employee Survey and Feedback.": "Encuesta y comentarios de los empleados",
    "Vantage Fit": "Vantage Fit",
    "Employee Health and Wellness.": "Salud y bienestar de los empleados.",
    "All-in-One": "Todo-en-uno",
    "Boost employee engagement with our AI-powered solutions.": "Impulse el compromiso de los empleados con nuestras soluciones impulsadas por IA",
    "Integrations": "Integraciones",
    "Seamless integrations with your existing HCM/HRIS platform.": "Integraciones perfectas con su plataforma HCM / HRIS existente",

    "Watch Product Videos": "Ver videos de productos",
    "All-in-One Solution": "Solución todo en uno",
    "Employee Recognition Platform": "Plataforma de reconocimiento de empleados",
    "Employee Pulse Survey": "Encuesta de pulso para empleados",
    "Corporate Wellness Platform": "Plataforma de bienestar corporativo",
    "Employee Benefits Platform": "Plataforma de beneficios para empleados",

    "Clients Overview": "Descripción de los clientes",
    "More than 400+ companies are enhancing their employee experience and building an engaged and happy workforce with us.": "Más de 400 empresas están mejorando la experiencia de sus empleados y creando una fuerza laboral comprometida y feliz con nosotros."
}
import {common} from './common'
import {footer} from './footer'
import { TestimonialEnglish } from './TestimonialEnglish'
import { header } from './header'

const additionalTrans = {
    "meta_title": "Bring Ease and Simplicity to Employee Engagement",
    "meta_desc": "Enrich your company culture by improving employee engagement with our employee rewards, on-spot recognition, employee feedback, and corporate wellness solutions designed for today's global workforce",
    "home_title": "Bring <1>Ease and Simplicity</1><br/> to Employee Engagement",
    "Enrich your company culture by improving employee engagement with our employee rewards,<br/> on-spot recognition, employee feedback, and corporate wellness solutions designed for today's global workforce.": "Enrich your company culture by improving employee engagement with our employee rewards,<br/> on-spot recognition, employee feedback, and corporate wellness solutions designed for today's global workforce.",
    "Our Partners in Expansion": "Our Partners in Expansion",
    "Trusted by over 400 Companies Around the World": "Trusted by over 400 Companies Around the World",
    "See more clients": "See more clients",
    "Trusted Partners": "Trusted Partners",
    "Making Employee Engagement Fun & Easy in over 400+ companies across the globe": "Making Employee Engagement Fun & Easy in over 400+ companies across the globe",
    "Read our success stories to discover how Vantage Circle helped companies connect, support, and recognize their workforce to transform their work culture and boost employee engagement.": "Read our success stories to discover how Vantage Circle helped companies connect, support, and recognize their workforce to transform their work culture and boost employee engagement.",
    "Check Success Stories": "Check Success Stories",
    "Employee<br/>Engagement": "Employee<br/>Engagements",
    "Increase<br/>in Employee Participation": "Increase<br/>in Employee Participation",
    "Reduction in<br/>Employee Absenteeism": "Reduction in<br/>Employee Absenteeism",
    "Increase in<br/>Peer-to-peer recognition": "Increase in<br/>Peer-to-peer recognition",
    "Our Solutions": "Our Solutions",
    "The All-in-one Employee Engagement and Wellness Platform": "The All-in-one Employee Engagement and Wellness Platform",
    "Our employee engagement platform combines rewards<br/> and recognition, employee benefits, employee feedback, and wellness programs into a unified total solution.": "Our employee engagement platform combines rewards<br/> and recognition, employee benefits, employee feedback, and wellness programs into a unified total solution.",
    "prod_vantagerewards": "<0>Vantage</0><1>Rewards</1>",
    "prod_vantageperks": "<0>Vantage</0><1>Perks</1>",
    "prod_vantagepulse": "<0>Vantage</0><1>Pulse</1>",
    "prod_vantagefit": "<0>Vantage</0><1>Fit</1>",
    "Know More": "Know More",
    "Vantage Rewards is a powerful SaaS-based reward and recognition platform that is easy to use and makes the employee recognition process easy, fun, cost-effective, and globally accessible. Enable your organization to recognize and appreciate in a meaningful yet seamless way with Vantage Rewards.": "Vantage Rewards is a powerful SaaS-based reward and recognition platform that is easy to use and makes the employee recognition process easy, fun, cost-effective, and globally accessible. Enable your organization to recognize and appreciate in a meaningful yet seamless way with Vantage Rewards.",
    "Vantage Perks is a comprehensive employee benefits platform that provides exclusive corporate deals, discounts, and cashback on a global catalog of top online and offline brands. Prioritizes your employees' financial well-being by enabling them to save money with Vantage Perks.": "Vantage Perks is a comprehensive employee benefits platform that provides exclusive corporate deals, discounts, and cashback on a global catalog of top online and offline brands. Prioritizes your employees' financial well-being by enabling them to save money with Vantage Perks.",
    "Vantage Pulse is a powerful employee survey tool that lets you collect, measure, and act on real-time employee feedback. Uncover actionable data, predict employee behavior and get an accurate picture of your organization's employee engagement levels with Vantage Pulse.": "Vantage Pulse is a powerful employee survey tool that lets you collect, measure, and act on real-time employee feedback. Uncover actionable data, predict employee behavior and get an accurate picture of your organization's employee engagement levels with Vantage Pulse.",
    "Vantage Fit is an employee wellness platform that enables organizations to nurture a healthy workforce by enabling a gamified wellness experience. Meet your workforce's wellness needs through team events and incentivizing health achievements with redeemable points with Vantage Fit.": "Vantage Fit is an employee wellness platform that enables organizations to nurture a healthy workforce by enabling a gamified wellness experience. Meet your workforce's wellness needs through team events and incentivizing health achievements with redeemable points with Vantage Fit.",
    "BENEFITS": "BENEFITS",
    "Why Vantage Circle?": "Why Vantage Circle?",
    "Explore all the possibilities to give your employees more reasons to become more engaged.": "Explore all the possibilities to give your employees more reasons to become more engaged.",
    "Flexible<br/> Rewarding": "Flexible Rewarding",
    "Peer-to-peer<br/> Recognition": "Peer-to-peer Recognition",
    "Employee<br/> Incentivization": "Employee Incentivization",
    "Perks and<br/> Privileges": "Employee Well-being",
    "Employee<br/> Empowerment": "Employee Empowerment",
    "In the Press": "In the Press",
    "Awards and Brand Mentions": "Awards and Brand Mentions",
    "Here's a look at some of our achievements over the years and mentions we got from market leaders.": "Here's a look at some of our achievements over the years and mentions we got from market leaders.",
    
    "We are changing the way HR manages Employee Engagement and Benefits programmes.": "We are changing the way HR manages Employee Engagement and Benefits programmes.",
    "In 2010, while discussing startup ideas, friends Anjan Pathak and Partha Neog stumbled upon the concept of employee engagement programmes": "In 2010, while discussing startup ideas, friends Anjan Pathak and Partha Neog stumbled upon the concept of employee engagement programmes",
    "Read more": "READ MORE",
    "Nine startups with promise": "Nine startups with promise",
    "With 90 percent of its workforce based out of the scenic river town of Guwahati, the seven-year-old startup has kept its operations": 
    "With 90 percent of its workforce based out of the scenic river town of Guwahati, the seven-year-old startup has kept its operations",
    "Vantage Circle: Recognize Employees with Gift Cards, Discounts, and Other Perks": "Vantage Circle: Recognize Employees with Gift Cards, Discounts, and Other Perks",
    "Maintaining satisfied and happy employees may build good karma, but it’s also a good business strategy. High turnover rates cost corporations significant sums of money in seeking, onboarding": "Maintaining satisfied and happy employees may build good karma, but it’s also a good business strategy. High turnover rates cost corporations significant sums of money in seeking, onboarding",
    "Explore More": "Explore More",
    
    "Global Capabilities": "Global Capabilities",
    "Associated Global Brands": "Associated Global Brands",
    "Reward Options": "Options de rémunération",
    "Countries": "Countries",
    "Companies": "Companies",
    "Corporate Employees": "Corporate Employees",
    "Free Resources": "FREE RESOURCES",
    "Our Resource Library Is Absolutely Free of Cost!": "Our Resource Library Is Absolutely Free of Cost!",
    "Keep yourself updated with our latest HR blogs, webinars, guides and ebooks, podcasts, and more.": "Keep yourself updated with our latest HR blogs, webinars, guides and ebooks, podcasts, and more.",
    "Blog": "Blog",
    "Insightful articles, best practices, and trends in HR innovation.": "Insightful articles, best practices, and trends in HR innovation.",
    "Read Now": "Read Now",
    "Free eBooks": "Free eBooks",
    "A free resources center with practical guides on HR management.": "A free resources center with practical guides on HR management.",
    "Download Now": "Download Now",
    "Influencer Podcast": "Influencer Podcast",
    "Listen to thought leaders on best HR practices and trends.": "Listen to thought leaders on best HR practices and trends.",
    "Listen Now": "Listen Now",
    "Doers Webinar": "Doers Webinar",
    "Watch our webinar series to get practical lessons on employee engagement strategies.": "Watch our webinar series to get practical lessons on employee engagement strategies.",
    "Watch Now": "Watch Now",
    "Want to Know More About Vantage Circle?": "Want to Know More about Vantage Circle?",
    "Contact Us": "Contact Us",
    "Talk to Us": "Talk to Us",

    "Compare packages": "Compare packages",
    "Rewards & Recognition": "Rewards & Recognition",
    "Redemption": "Redemption",
    "Analytics & Reporting": "Analytics & Reporting",
    "Social Integrations": "Social Integrations",
    "Monetary awards": "Monetary awards",
    "HRMS/HRIS Integrations": "HRMS/HRIS Integrations",
    "SSO Integration": "SSO Integration",
    "Branding": "Branding",
    "Data & Security": "Data & Security",
    "Utility": "Utility",
    "Support": "Support",
    "Consultation": "Consultation",
    "AI Capabilities": "AI Capabilities",
    "Instant non-monetary recognition": "Instant non-monetary recognition",
    "Automated greetings": "Automated greetings",
    "Social recognition feed": "Social recognition feed",
    "Wall of fame": "Wall of fame",
    "Gamified leaderboard": "Gamified leaderboard",
    "Team awarding": "Team awarding",
    "Panel voting awards": "Panel voting awards",
    "Employee nudges": "Employee nudges",
    "Configurable workflow": "Configurable workflow",
    "LinkedIn sharing": "LinkedIn sharing",
    "Long service awards": "Long service awards",
    "Service yearbook": "Service yearbook",
    "Amazon store": "Amazon store",
    "Experiences & holidays": "Experiences & holidays",
    "Gift vouchers": "Gift vouchers",
    "Merchandise": "Merchandise",
    "Multi purpose wallet": "Multi purpose wallet",
    "Languages": "Languages",
    "SOLI matrix currency conversion": "SOLI matrix currency conversion",
    "Multi currency budget": "Multi currency budget",
    "Native currency redemption": "Native currency redemption",
    "Company wide announcements": "Company wide announcements",
    "HR dashboard": "HR dashboard",
    "Manager dashboard": "Manager dashboard",
    "Manager monthly summary": "Manager monthly summary",
    "Employee adoption analytics": "Employee adoption analytics",
    "Team adoption analytics": "Team adoption analytics",
    "Corporate branding": "Corporate branding",
    "Registration landing page": "Registration landing page",
    "Budget Management": "Budget Management",
    "Team Awarding": "Team Awarding",
    "Delegation": "Delegation",
    "Dedicated account manager": "Dedicated account manager",
    "User e-mail/chat support": "User e-mail/chat support",
    "Award Recommendations": "Award Recommendations",
    "Smart Compose": "Smart Compose",
    "Dashboard Insights": "Dashboard Insights",
    "Included Products": "Included Products",
    "Cashback offers": "Cashback offers",
    "Classified": "Classified",
    "Custom offers": "Custom offers",
    "Exclusive offers": "Exclusive offers",
    "Add On Products": "dd On Products",
    "Steps Challenges": "Steps Challenges",
    "Multi-activity Challenges": "Multi-activity Challenges",
    "Team Challenges": "Team Challenges",
    "Journey Challenges": "Journey Challenges",
    "Physical Activity Tracking": "Physical Activity Tracking",
    "Nutrition Tracking": "Nutrition Tracking",
    "Mental Wellbeing": "Mental Wellbeing",
    "HR Admin Dashboard": "HR Admin Dashboard",
    "Wellness Incentives and Rewards": "Wellness Incentives and Rewards",
    "Global Redemption Catalog": "Global Redemption Catalog",
    "Gamified Challenges": "Gamified Challenges",
    "Real-time Leaderboard": "Real-time Leaderboard",
    "SSO login and HRMS Integrations": "SSO login and HRMS Integrations",
    "Wellness Library": "Wellness Library",
    "Partner Offerings": "Partner Offerings",
    "Anonymous feedback": "Anonymous feedback",
    "Employee Experience Insights": "Employee Experience Insights",
    "Lifecycle Experience Insights": "Lifecycle Experience Insights",
    "eNPS survey": "eNPS survey",
    "Standard Questions": "Standard Questions",
    "Unlimited Questions": "Unlimited Questions",
    "Heatmap analysis": "Heatmap analysis",
    "Industry benchmarking": "Industry benchmarking",
    "Winner of top global awards in Employee Recognition and Engagement": "Winner of top global awards in Employee Recognition and Engagement",
    "companies, from SMEs to Fortune 500, trust us!": "companies, from SMEs to Fortune 500, trust us!",
    "offers": "offers",
    "Included Products": "Included Products",
    "Add On Products": "Add On Products",
    "Our global capabilities": "Our global capabilities",
    "Reward Options": "Reward Options",
    "Languages": "Languages",
    "Companies": "Companies",
    "Users": "Users",
    "Delivering simple yet effective employee engagement solutions globally.": "Delivering simple yet effective employee engagement solutions globally.",
    "See all clients": "See all clients"
}

export const en = {
    ...common,
    ...footer,
    ...header,
    ...additionalTrans,
    ...TestimonialEnglish
}
export const  de_footer = {
    "Quick Links": "Schnelle Links ",
 
    "About us": "Uber uns",
     
    "Advertise with us": "Werben Sie mit uns",
   
    "Partner with us": "Partner mit uns",
   
    "In the News": "Meldungen",
   
    "Download App": "Herunterladen Sie",
   
    "Contact us": "Kontaktiere Uns",
   
    "Solutions": "Lösungen",
   
    "Employee Recognition": "Mitarbeiter Erkennung",
   
    "Employee Discount": "Mitarbeiter Rabatte ",
   
    "Employee Survey": "Mitarbeiter Befragung ",
   
    "Employee Wellness": "Mitarbeiter Wohlbefinden ",
   
    "All-in-One Solution": "All-in-One Lösung ",
   
    "Resources Center": "Ressourcen Zentrum ",
   
    "Blog": "Blog",
     
    "Integration": "Integration",
   
    "Learn More": "Erfahren Sie mehr",
   
    "Employee Engagement": "Mitarbeiter Engagement",
   
    "Rewards & Recognition": "Belohnungen und Erkennung",
   
    "Employee Experience": "Mitarbeiter Erfahrung",
   
    "Pulse Survey": "Pulsbefragung",
   
    "Employee Benefits": "Mitarbeiter Prämien ",
   
    "Employee Retention": "Mitarbeiter Retention",
   
    "Collaborate with us to provide your employees<br/> an engaging and rewarding experience.": "Colabora con nosotros para proporcionar a tus empleados<br/> una experiencia atractiva y gratificante.",
     
    "FREE DOWNLOAD": "Kostenlose Herunterladung",
   
    "Google Play": "Google Play",
   
    "App Store": "App Store",
   
    "Locations": "Standorte",
   
    "All rights reserved.": "© Vantage Circle. 2024 Todos los derechos reservados.",
   
    "Security": "Sicherheit",
   
    "Terms and Conditions": "Geschäftbedingungen",
   
    "Privacy Policy": "Datenschutz",

    "Cookie Policy": "Cookie-Richtlinie",
   
    "Image Copyright": "Bild Urherberrecht",

    "Allow" : "Akzeptieren",

    "Deny" : "Ablehnen",

    "ConsentTextHeader": "Unsere Website verwendet Cookies!",
    
    "ConsentTextBody": "Wir verwenden eigene Cookies und Cookies von Dritten, um Inhalte und Anzeigen zu personalisieren, unsere Website sicher zu betreiben und den Datenverkehr zu analysieren. Sie können der Verwendung von Cookies zustimmen oder sie ablehnen.",

    "GDPR" : "DSGVO",

    "DPDP" : "DPDP"
 }
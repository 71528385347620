import { de } from "../../locales/translations/de"
import { en } from "../../locales/translations/en"
import { es } from "../../locales/translations/es"
import { fr } from "../../locales/translations/fr"
// import { nl } from "../../locales/translations/nl";
// import { pt } from "../../locales/translations/pt";

export const languageObj = {
  de: de,
  fr: fr,
  en: en,
  // pt: pt,
  // nl: nl,
  es: es,
}

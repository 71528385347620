import { es_footer } from "./footer";
import { es_header } from "./header";
import { TestimonialSpanish } from "./TestimonialSpanish";

export const common = {
    "Allow Cookies" : "Aceptar cookies",
    "Decline Cookies" : "No aceptar cookies"
}

const additionalSpanishTrans = {
  "Global Capabilities": "Capacidades Globales",
  "Compare packages": "Comparar paquetes",
  "Rewards & Recognition": "Retribuciones y reconocimiento",
  "Redemption": "Redención",
  "Analytics & Reporting": "Análisis e informes",
  "Social Integrations": "Integraciones sociales",
  "Monetary awards": "Premios monetarios",
  "HRMS/HRIS Integrations": "Integración de los HRIS/HRMS ",
  "SSO Integration": "Integración de las OSS",
  "Branding": "Marca",
  "Data & Security": "Seguridad de los datos",
  "Utility": "Utilidad",
  "Support": "Apoyo",
  "Consultation": "Consulta",
  "AI Capabilities": "Capacidades de IA",
  "Instant non-monetary recognition": "Reconocimiento instantáneo no monetario",
  "Automated greetings": "Saludos automatizados",
  "Social recognition feed": "Nourriture de reconnaissance sociale",
  "Wall of fame": "Muro de la fama",
  "Gamified leaderboard": "Tabla de clasificación gamified",
  "Team awarding": "Asignación de equipos",
  "Panel voting awards": "Panel Premios ",
  "Employee nudges": "Empujones para empleados",
  "Configurable workflow": "Flujo de trabajo configurable",
  "LinkedIn sharing": "Compartir en LinkedIn",
  "Long service awards": "Largos premios de servicios",
  "Service yearbook": "Anuario de servicio",
  "Amazon store": "Tienda Amazon",
  "Experiences & holidays": "Experiencias y vacaciones",
  "Gift vouchers": "Bonos de regalo",
  "Merchandise": "Mercancías",
  "Multi purpose wallet": "Cartera multiuso",
  "Languages": "Idiomas",
  "SOLI matrix currency conversion": "Conversión de moneda de la matriz SOLI",
  "Multi currency budget": "Presupuesto multimoneda",
  "Native currency redemption": "Reembolso de la moneda nacional",
  "Company wide announcements": "Anuncios en toda la empresa",
  "HR dashboard": "Tablero de recursos humanos",
  "Manager dashboard": "Manager dashboard",
  "Manager monthly summary": "Manager monthly summary",
  "Employee adoption analytics": "Análisis de la adopción de los empleados",
  "Team adoption analytics": "Análisis de la adopción en equipo",
  "Corporate branding": "Marca corporativa",
  "Registration landing page": "Página de inicio de la inscripción",
  "Budget Management": "Gestión del presupuesto",
  "Team Awarding": "Asignación de equipos",
  "Delegation": "Delegación",
  "Dedicated account manager": "Gestor de cuentas dedicado",
  "User e-mail/chat support": "Soporte de correo electrónico/chat del usuario",
  "Award Recommendations": "Recomendaciones relativas a los premios",
  "Smart Compose": "Compostar inteligente",
  "Dashboard Insights": "Dashboard Insights",
  "Included Products": "Productos incluidos",
  "Cashback offers": "Ofertas de devolución de efectivo",
  "Classified": "Clasificado",
  "Custom offers": "Ofertas personalizadas",
  "Exclusive offers": "Offres exclusives",
  "Add On Products": "Añadir sobre los productos",
  "Steps Challenges": "Steps Challenges",
  "Multi-activity Challenges": "desafíos de múltiples actividades",
  "Team Challenges": "Retos de los equipos",
  "Journey Challenges": "Desafíos del viaje",
  "Physical Activity Tracking": "Seguimiento de la actividad física",
  "Nutrition Tracking": "Seguimiento nutricional",
  "Mental Wellbeing": "Bienestar mental",
  "HR Admin Dashboard": "HR Admin Dashboard",
  "Wellness Incentives and Rewards": "Incentivos y recompensas para el bienestar",
  "Global Redemption Catalog": "Global Redemption Catalog",
  "Gamified Challenges": "Desafíos gamificados",
  "Real-time Leaderboard": "Leaderboard en tiempo real",
  "SSO login and HRMS Integrations": "Inicio de sesión de SSO e integraciones de HRMS",
  "Wellness Library": "Biblioteca Wellness",
  "Partner Offerings": "Ofertas de socios",
  "Anonymous feedback": "Comentarios anónimos",
  "Employee Experience Insights": "Perspectivas de la experiencia de los empleados",
  "Lifecycle Experience Insights": "Perspectivas de la experiencia del ciclo de vida",
  "eNPS survey": "Encuesta eNPS",
  "Standard Questions": "Preguntas estándar",
  "Unlimited Questions": "Preguntas ilimitadas",
  "Heatmap analysis": "Análisis del mapa de calor",
  "Industry benchmarking": "Evaluación comparativa de la industria",
  "companies, from SMEs to Fortune 500, trust us!": "empresas, desde PYMEs hasta Fortune 500, confían en nosotros!",
  "offers": "ofertas",
  "Included Products": "Productos Incluidos",
  "Add On Products": "Productos Adicionales",
  "Winner of top global awards in Employee Recognition and Engagement": "Ganador de los principales premios globales en Reconocimiento y Compromiso de Empleados",
  "Our global capabilities": "Nuestras capacidades globales",
  "Reward Options": "opciones de recompensas",
  "Languages": "idiomas",
  "Companies": "empresas",
  "Users": "usuarios",
  "Delivering simple yet effective employee engagement solutions globally.": "Ofreciendo soluciones de compromiso de empleados simples pero efectivas a nivel mundial.",
  "See all clients": "Ver todos los clientes",
  "Countries": "Países"
}

export const es = {
    ...es_footer,
    ...es_header,
    ...common,
    ...additionalSpanishTrans,
    ...TestimonialSpanish
}
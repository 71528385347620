export const es_footer = {
    "Quick Links": "Enlaces rápidos",

    "About us": "Sobre nosotros",
    
    "Careers - We're hiring!": "Carreras - ¡Estamos contratando!",
    
    "Advertise with us": "Anuncie con nosotros",
    
    "Partner with us": "Socio con nosotros",
    
    "In the News": "En las noticias",
    
    "Download App": "Descargar aplicación",
    
    "Contact us": "Contacte con nosotros",
    
    "Solutions": "Soluciones",
    
    "Employee Recognition": "Reconocimiento de empleados",
    
    "Employee Discount": "Descuento de empleado",
    
    "Employee Survey": "Encuesta de empleados",
    
    "Employee Wellness": "Bienestar del empleado",
    
    "All-in-One Solution": "Solución Todo-en-Uno",
    
    "Resources Center": "Centro de recursos",
    
    "Blog": "Blog",
    
    "Guides and Ebook": "Guías y Ebook",
    
    "Case Studies": "Casos prácticos",
    
    "Vantage Doers Webinars": "Seminarios web de Vantage Doers",
    
    "Vantage Podcasts": "Podcasts de Vantage",
    
    "Integration": "Integración",
    
    "Learn More": "Aprende Más",
    
    "Employee Engagement": "Compromiso con los empleados",
    
    "Rewards & Recognition": "Recompensas y reconocimiento",
    
    "Employee Experience": "Experiencia del empleado",
    
    "Pulse Survey": "Encuesta de pulsos",
    
    "Diversity and Inclusion": "Diversidad e inclusión",
    
    "Leadership Qualities": "Cualidades de liderazgo",
    
    "Employee Motivation": "Motivación del empleado",
    
    "Employee Benefits": "Beneficios para empleados",
    
    "Work from home Policy": "Política de trabajo desde casa",
    
    "Employee Retention": "Retención de empleado",
    
    "360 Degree Appraisal": "Evaluación de 360 grados",
    
    "Collaborate with us to provide your employees<br/> an engaging and rewarding experience.": "Colabora con nosotros para proporcionar a tus empleados<br/> una experiencia atractiva y gratificante.",
    
    "Download app": "Descargar aplicación",
    
    "FREE DOWNLOAD": "DESCARGA GRATUITA",
    
    "Google Play": "Google Play",
    
    "App Store": "App Store",
    
    "Locations": "Ubicaciones",
    
    "All rights reserved.": "© Vantage Circle. 2024 Todos los derechos reservados.",
    
    "Security": "Seguridad",
    
    "Terms and Conditions": "Términos y condiciones",
    
    "Privacy Policy": "Política de privacidad",

    "Cookie Policy": "Política de cookies",
    
    "Image Copyright": "Derechos de autor de imagen",

    "Allow" : "Aceptar todas las cookies ",

    "Deny" : "Rechazar",

    "ConsentTextHeader": "Nuestro sitio web utiliza cookies.",
    
    "ConsentTextBody": "Utilizamos cookies propias y de terceros para personalizar el contenido y los anuncios, proporcionar funciones de redes sociales, operar nuestro sitio web de manera segura y analizar el tráfico. Puedes configurar y aceptar el uso de cookies a continuación o rechazar el mismo.",

    "GDPR" : "GDPR",

    "DPDP" : "DPDP"

}
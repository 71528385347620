export const header = {
    hellobar1: "Découvrez les secrets d'une plateforme d'engagement des employés efficace !",
    hellobar2:
    "Téléchargez notre guide exclusif pour optimiser votre stratégie RH.",
    hellobarcta: "Téléchargez maintenant",
    "Schedule a Free Demo": "Planifiez une démo gratuite",
    "Watch Video": "Regardez la vidéo",
    "Home": "Accueil",
    "About us": "A propos de nous",
    "Contact us": "Contactez-nous",
    "Download app": "Téléchargez l'application",
    "Sign in": "S'inscrire",
    "Solutions": "Solutions",
    "Pricing": "Tarification",
    "Clients": "Clients",
    "Partners": "Partenaires",
    "Resources": "Ressources",
    "Existing customer": "Client existant",

    "Vantage Rewards": "Vantage Rewards",
    "Employee Rewards and Recognition.": "Récompenses et Reconnaissance des Employés.",
    "Vantage Perks": "Vantage Perks",
    "Employee Discount and Benefits.": "Remis et avantages sociaux pour les employés.",
    "Vantage Pulse": "Vantage Pulse",
    "Employee Survey and Feedback.": "Sondage et Rétroaction des Employés",
    "Vantage Fit": "Vantage Fit",
    "Employee Health and Wellness.": "Santé et bien-être des employés.",
    "All-in-One": "Tout-en-un",
    "Boost employee engagement with our AI-powered solutions.": "Stimuler l'engagement des employés avec nos solutions basées sur l'IA.",
    "Integrations": "Integrations",
    "Seamless integrations with your existing HCM/HRIS platform.": "Intégrations transparentes avec votre plateforme La gestion du Capital Humaine/Système d'information RH existante.",

    "Watch Product Videos": "Regarder les vidéos des produits",
    "All-in-One Solution": "Solution tout-en-un",
    "Employee Recognition Platform": "Plateforme de reconnaissance des employés",
    "Employee Pulse Survey": "Sondage Pulse aux Employés",
    "Corporate Wellness Platform": "Plateforme de bien-être en entreprise",
    "Employee Benefits Platform": "Plateforme d'avantages sociaux",

    "Clients Overview": "Aperçu des clients",
    "More than 400+ companies are enhancing their employee experience and building an engaged and happy workforce with us.": "Plus de 400 entreprises améliorent l'expérience de leurs employés et créent avec nous une main-d'œuvre engagée et heureuse.",
    "Success Stories": "Histoires de succès",
    "Read our client success stories and learn how organisations are using Vantage Circle to build an engaged and productive workplace.": "Lisez les histoires de réussite de nos clients et découvrez comment les organisations utilisent Vantage Circle pour créer un lieu de travail engagé et productif.",
    "Top Success Stories": "Principales réussites",
    "Ingram Micro Witnessed an 8% Increase in Employee Engagement with Vantage Rewards in 2020": "Ingram Micro a constaté une augmentation de 8 % de l'engagement des employés avec Vantage Rewards en 2020",
    "Accor improved its Employee Recognition by 53% through Vantage Circle": "Accor a amélioré de 53 % la reconnaissance de ses employés grâce à Vantage Circle.",
    "Vantage Circle helped TATA Motors in achieving 82% rise in peer to peer recognition": "Vantage Circle a aidé TATA Motors à atteindre une augmentation de 82 % de la reconnaissance entre pairs",

    "Blog": "Blog",
    "Insightful articles, best practices and trends in HR innovation": "Articles perspicaces, meilleures pratiques et tendances en innovation RH",
    "Guides and eBooks": "Guides et livres électroniques",
    "A free resources center with practical guides on HR management.": "Un centre de ressources gratuit avec des guides pratiques sur la gestion des RH.",
    "Influencers Podcast": "Podcast des influenceurs",
    "Listen to thought leaders on best HR practices and trends.": "Écoutez les leaders d'opinion sur les meilleures pratiques et tendances RH.",
    "Doers Webinar": "Webinar",
    "Watch our webinar series to get practical lessons on employee engagement strategies.": "Regardez notre série de webinaires pour obtenir des leçons pratiques sur les stratégies d'engagement des employés.",

    "Recent Posts": "Messages récents",
    "7 Techniques for Employee Stress Management in the workplace": "7 techniques de gestion du stress des employés en milieu de travail",
    "5 Easy Steps To Build A Successful Severance Pay Plan": "5 étapes faciles pour créer un plan d'indemnité de départ réussi",
    "30 Email Etiquette Rules Every HR Should Know": "30 règles d'étiquette du courriel électronique que chaque RH devrait connaître",
    "Transactional Leadership: Meaning, Pros & Cons, Relevance": "Leadership transactionnel : signification, avantages et inconvénients, pertinence",
    "View all posts": "Voir tous les postes"


}
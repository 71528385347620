export const footer = {
  "Quick Links": "Liens rapides",

  "About us": "A propos de nous",

  "Careers - We're hiring!": "Carrières - Nous recrutons !",

  "Advertise with us": "Faites de la publicité avec nous",

  "Partner with us": "Devenez notre partenaire",

  "In the News": "Dans la presse",

  "Download App": "Téléchargez l'application",

  "Contact us": "Contactez-nous",

  Solutions: "Solutions",

  "Employee Recognition": "Reconnaissance des employés",

  "Employee Discount": "Remise pour les employés",

  "Employee Survey": "Sondage auprès des employés",

  "Employee Wellness": "Bien-être des employés",

  "All-in-One Solution": "Une solution tout-en-un",

  "Resources Center": "Centre de ressources",

  Blog: "Blog",

  "Guides and Ebook": "Guides et livres électroniques",

  "Case Studies": "Études de cas",

  "Vantage Doers Webinars": "Webinaire Vantage",

  "Vantage Podcasts": "Podcasts de Vantage",

  Integration: "Intégration",

  "Learn More": "En savoir plus",

  "Employee Engagement": "Engagement des employés",

  "Rewards & Recognition": "Récompenses et reconnaissance",

  "Employee Experience": "Expérience des employés",

  "Pulse Survey": "Sondage Pulse",

  "Diversity and Inclusion": "Diversité et inclusion",

  "Leadership Qualities": "Compétences en leadership",

  "Employee Motivation": "Motivation du personnel",

  "Employee Benefits": "Avantages sociaux",

  "Work from home Policy": "Politique de travail à domicile",

  "Employee Retention": "Rétention des employés",

  "360 Degree Appraisal": "Evaluation à 360 degrés",

  "Collaborate with us to provide your employees<br/> an engaging and rewarding experience.":
    "Collaborez avec nous pour offrir à vos employés<br/> une expérience intéressante et enrichissante.",

  "Download app": "Télécharger l'application",

  "FREE DOWNLOAD": "TÉLÉCHARGEMENT GRATUIT",

  "Google Play": "Google Play",

  "App Store": "App Store",

  Locations: "Sites",

  "All rights reserved.": "© Vantage Circle. 2024 Tous droits réservés.",

  Security: "Sécurité",

  "Terms and Conditions": "Mentions légales & CGU",

  "Privacy Policy": "Confidentialité",

  "Cookie Policy": "Cookies",

  "Image Copyright": "Droits d'auteur",

  Allow: "Accepter",

  Deny: "Déclin",

  ConsentTextHeader: "Notre site utilise des cookies!",

  ConsentTextBody:
    "Nous utilisons nos propres cookies et ceux de tiers pour personnaliser le contenu, les publicités, exploiter notre site Web en toute sécurité et analyser le trafic. Vous pouvez accepter l'utilisation de cookies ou la refuser.",

  GDPR: "RGPD",

  "DPDP" : "DPDP"
}

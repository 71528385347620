export const common = {
    "Testimonials": "Testimonials",
    "Our Customers Love Us as Much as We Love Them.<br/> See for Yourself!": "Our Customers Love Us as Much as We Love Them.<br/> See for Yourself!",
    "We are very happy to be associated with InfyGold+, a part of Rewards and Recognition Program at Infosys, and we run this program with Vantage Circle. It has been awesome to have worked with Vantage Circle and the team has helped us provide great experiences to our employees through a single platform, which is easy to use, provides a gamut of options and most importantly, is available on personal smartphones through the app.": "We are very happy to be associated with InfyGold+, a part of Rewards and Recognition Program at Infosys, and we run this program with Vantage Circle. It has been awesome to have worked with Vantage Circle and the team has helped us provide great experiences to our employees through a single platform, which is easy to use, provides a gamut of options and most importantly, is available on personal smartphones through the app.",
    "AVP and Head of Internal Communication": "AVP and Head of Internal Communication",
    "Vantage Rewards is the first online-based rewards and recognition platform that we’ve implemented. The point-based rewarding system has made tracking of rewards and it’s data more manageable. The report feature has been helpful in understanding the budget and reward allocations. It has also given us the option of analyzing where we are in terms of recognizing our employees. Every organization, like ours, would certainly benefit from using the platform.": "Vantage Rewards is the first online-based rewards and recognition platform that we’ve implemented. The point-based rewarding system has made tracking of rewards and it’s data more manageable. The report feature has been helpful in understanding the budget and reward allocations. It has also given us the option of analyzing where we are in terms of recognizing our employees. Every organization, like ours, would certainly benefit from using the platform.",
    "Manager – Employee Relations": "Manager – Employee Relations",
    "Vantage Rewards have been a part of our renewed efforts to establish a culture of recognition by giving our workforce the flexibility to choose their own rewards. The Instant recognition feature makes it convenient for us to reward good work immediately. From looking forward to being incentivized, having the choice of multiple reward options, and the user-friendly interface; has caused our people to get more motivated than ever.": "Vantage Rewards have been a part of our renewed efforts to establish a culture of recognition by giving our workforce the flexibility to choose their own rewards. The Instant recognition feature makes it convenient for us to reward good work immediately. From looking forward to being incentivized, having the choice of multiple reward options, and the user-friendly interface; has caused our people to get more motivated than ever.",
    "HR - Employee Experience & Internal Communications": "HR - Employee Experience & Internal Communications",
    "The Rewards and Recognition program we had before was very different from what Vantage Circle offers. After Vantage Circle, everything became a lot easier and structured. When we launched the program we saw an engagement of 55-65% which is quite a good according to me. Right now our plan is to improve our internal communication around it. The most popular feature here is the badges, people are really enjoying giving each other badges.": "The Rewards and Recognition program we had before was very different from what Vantage Circle offers. After Vantage Circle, everything became a lot easier and structured. When we launched the program we saw an engagement of 55-65% which is quite a good according to me. Right now our plan is to improve our internal communication around it. The most popular feature here is the badges, people are really enjoying giving each other badges.",
    "Internal Communications Manager": "Internal Communications Manager",
    "We really like the way that Vantage Circle has created a wave of family spirit within Adecco and the way the platform serves all our colleagues across the branches and it gets everyone together. The rewards points given to our employees and they are free to choose wherever they want to spend their points, which is a big win for us. Vantage Circle has done a great job for Adecco and I would definitely recommend it for other companies as well.": "We really like the way that Vantage Circle has created a wave of family spirit within Adecco and the way the platform serves all our colleagues across the branches and it gets everyone together. The rewards points given to our employees and they are free to choose wherever they want to spend their points, which is a big win for us. Vantage Circle has done a great job for Adecco and I would definitely recommend it for other companies as well.",
    "Human Resource": "Human Resource",
    "Wipro Advantage (powered by Vantage Circle) was launched last year to provide excusive privileges to our employees pan India. The feedback received from the employees has been very positive and we feel it is an amazing value proposition for our employees." : "Wipro Advantage (powered by Vantage Circle) was launched last year to provide exclusive privileges to our employees pan India. The feedback received from the employees has been very positive and we feel it is an amazing value proposition for our employees.",
    "Vice President and HR Head": "Vice President and HR Head",
    "Vantage Circle has transformed our previous manual rewarding process. With the ease of use, streamlined rewards distribution, and flexibility of choosing rewards, our TAT time has reduced considerably. Vantage Circle’s admin dashboard gives us incredible insights into data-backed engagement patterns. We have seen a chain reaction of enhanced work performance in all our teams. It really is the perfect solution to hassle-free rewards and recognition.": "Vantage Circle has transformed our previous manual rewarding process. With the ease of use, streamlined rewards distribution, and flexibility of choosing rewards, our TAT time has reduced considerably. Vantage Circle’s admin dashboard gives us incredible insights into data-backed engagement patterns. We have seen a chain reaction of enhanced work performance in all our teams. It really is the perfect solution to hassle-free rewards and recognition.",
    "HR Business Partner": "HR Business Partner",
    "It's been slightly over a year since we launched our rewards and recognition platform in partnership with Vantage circle. FirstReward is a good online platform which enables us to reward employees across the globe, encourage bottom up, top down and peer to peer appreciation. Moreover, the tool has also enabled us to further build and strengthen our existing rewards and appreciation culture. The team has partnered with us at every stage to rollout enhancements where possible.": "It's been slightly over a year since we launched our rewards and recognition platform in partnership with Vantage circle. FirstReward is a good online platform which enables us to reward employees across the globe, encourage bottom up, top down and peer to peer appreciation. Moreover, the tool has also enabled us to further build and strengthen our existing rewards and appreciation culture. The team has partnered with us at every stage to roll out enhancements where possible.",
    "Vantage Circle has made our Rewarding and Recognition process seamless, and we are now empowered to instantly recognize and celebrate great work. Giving the employees monetary awards or acknowledging other internal accomplishments has never been easier. The point-based reward gives employees the flexibility to choose their gift cards from various online/retail stores. It encourages employee motivation by showcasing their achievements of milestones and recognitions via social leaderboard on the platform. It also helps us build employee satisfaction in the long run and a culture where recognition is never delayed.": "Vantage Circle has made our Rewarding and Recognition process seamless, and we are now empowered to instantly recognize and celebrate great work. Giving the employees monetary awards or acknowledging other internal accomplishments has never been easier. The point-based reward gives employees the flexibility to choose their gift cards from various online/retail stores. It encourages employee motivation by showcasing their achievements of milestones and recognitions via social leaderboard on the platform. It also helps us build employee satisfaction in the long run and a culture where recognition is never delayed.",
    "Associate Manager": "Associate Manager",
    "I got to know about the Vantage Circle platform from various colleagues who are working with different companies. Vantage Rewards platform helped us route our Rewards and Recognition (R&R) and also helped in tracking our R&R budget allocations. The most amazing feature provided by VC is the points to money variant, i.e 1 point= Rs.1. Moreover, the manager points are really going smoothly and we just wanted this nomination process to be incorporated by the portal. I will definitely recommend the Vantage Rewards platform to other organizations.": "I got to know about the Vantage Circle platform from various colleagues who are working with different companies. Vantage Rewards platform helped us route our Rewards and Recognition (R&R) and also helped in tracking our R&R budget allocations. The most amazing feature provided by VC is the points to money variant, i.e 1 point= Rs.1. Moreover, the manager points are really going smoothly and we just wanted this nomination process to be incorporated by the portal. I will definitely recommend the Vantage Rewards platform to other organizations.",
    "Associate Manager- HR": "Associate Manager- HR",
    "Vantage Circle has helped us revamp our Rewards process entirely and gave us the freedom to choose from various options available online as per the individual specific needs. The online platform has helped boost morale for the employees as part of Escalon Family even during COVID-19. It would not have been possible without such a platform when the employees were scattered across the country. It helped us big time and has been our preferred option for all our employee motivation activities. We are glad to be associated with Vantage Circle and look forward to a long-term partnership with them.": "Vantage Circle has helped us revamp our Rewards process entirely and gave us the freedom to choose from various options available online as per the individual specific needs. The online platform has helped boost morale for the employees as part of Escalon Family even during COVID-19. It would not have been possible without such a platform when the employees were scattered across the country. It helped us big time and has been our preferred option for all our employee motivation activities. We are glad to be associated with Vantage Circle and look forward to a long-term partnership with them.",
    "Lead HR": "Lead HR",
    "Wipro Advantage (powered by Vantage Circle) was launched last year to provide excusive privileges to our employees pan India. The feedback received from the employees has been very positive and we feel it is an amazing value proposition for our employees.": "Wipro Advantage (powered by Vantage Circle) was launched last year to provide excusive privileges to our employees pan India. The feedback received from the employees has been very positive and we feel it is an amazing value proposition for our employees.",
    "Testimonials from our Brand Partners": "Testimonials from our Brand Partners",
    "At EarlySalary, we are looking to help young salaried professionals every time they fall short of cash. Our partnership with Vantage Circle has been instrumental in helping us reach 1 lakh loans and is one of the top partners for EarlySalary. Vantage Circle has helped us reach out and engage with a large corporate audience.": "At EarlySalary, we are looking to help young salaried professionals every time they fall short of cash. Our partnership with Vantage Circle has been instrumental in helping us reach 1 lakh loans and is one of the top partners for EarlySalary. Vantage Circle has helped us reach out and engage with a large corporate audience.",
    "Vantage Circle helped us choose and customize offers which helped us grow our business by targeting a trusted network of corporate employees.": "Vantage Circle helped us choose and customize offers which helped us grow our business by targeting a trusted network of corporate employees.",
    "Regional Head": "Regional Head",
    "Vantage Circle allowed us to reach out to our ideal audience and build brand awareness and generate consistent sales every month.": "Vantage Circle allowed us to reach out to our ideal audience and build brand awareness and generate consistent sales every month.",
    "National Brand Head": "National Brand Head",
    "Not Found": "Not Found",
    "You just hit a route that doesn't exist.": "You just hit a route that doesn't exist",
    "gohome": "Go <1>Home</1> to start over.",
    "Vantage Circle Platform was implemented to  support existing Reward & Recognition Process. Vantage Reward Points can be availed easily by Employees providing complete freedom to choose from various bouquet of Services and Online options available as per the individual choices (have personalized experience). The online platform made it easier to have instant gratification possible. This Platform is now widely used across all the Functions / Locations. Reward Points are also being given for any Competitions or driving New Initiatives with Spot Prizes given to the Winners. Vantage Reward Points helped in boosting Employees morale even during the pandemic situation. We look forward to a long term partnership with Vantage Circle exploring other features to further enhance R&R Function at Tata Power." : "Vantage Circle Platform was implemented to  support existing Reward & Recognition Process. Vantage Reward Points can be availed easily by Employees providing complete freedom to choose from various bouquet of Services and Online options available as per the individual choices (have personalized experience). The online platform made it easier to have instant gratification possible. This Platform is now widely used across all the Functions / Locations. Reward Points are also being given for any Competitions or driving New Initiatives with Spot Prizes given to the Winners. Vantage Reward Points helped in boosting Employees morale even during the pandemic situation. We look forward to a long term partnership with Vantage Circle exploring other features to further enhance R&R Function at Tata Power.",
    "Allow Cookies" : "Allow Cookies",
    "Deny" : "Deny",
    "Decline Cookies" : "Decline Cookies",
    "ConsentTextHeader": "We Value Your Privacy.",
    "ConsentTextBody": "This website utilizes cookies to optimize your browsing experience and collect analytics concerning our visitors by default. If you decline, your information will not be tracked. For additional information, kindly refer to our"
}
export const TestimonialSpanish = {
    "We are very happy to be associated with InfyGold+, a part of Rewards and Recognition Program at Infosys, and we run this program with Vantage Circle. It has been awesome to have worked with Vantage Circle and the team has helped us provide great experiences to our employees through a single platform, which is easy to use, provides a gamut of options and most importantly, is available on personal smartphones through the app."
    :"Estamos muy contentos de estar asociados con InfyGold+, parte del Programa de Recompensas y Reconocimiento en Infosys, y gestionamos este programa con Vantage Circle. Ha sido increíble trabajar con Vantage Circle y el equipo nos ha ayudado a proporcionar excelentes experiencias a nuestros empleados a través de una única plataforma, que es fácil de usar, ofrece una gama de opciones y, lo más importante, está disponible en smartphones personales a través de la aplicación.",

    "We really like the way that Vantage Circle has created a wave of family spirit within Adecco and the way the platform serves all our colleagues across the branches and it gets everyone together. The rewards points given to our employees and they are free to choose wherever they want to spend their points, which is a big win for us. Vantage Circle has done a great job for Adecco and I would definitely recommend it for other companies as well."
    :"Nos gusta mucho la forma en que Vantage Circle ha creado un ambiente de espíritu familiar dentro de Adecco y cómo la plataforma sirve a todos nuestros colegas en las sucursales y los une a todos. Los puntos de recompensa que se les dan a nuestros empleados y son libres de elegir dónde quieren gastar sus puntos, lo que es una gran victoria para nosotros. Vantage Circle ha hecho un excelente trabajo para Adecco y definitivamente lo recomendaría para otras empresas también.",

    "Vantage Rewards have been a part of our renewed efforts to establish a culture of recognition by giving our workforce the flexibility to choose their own rewards. The Instant recognition feature makes it convenient for us to reward good work immediately. From looking forward to being incentivized, having the choice of multiple reward options, and the user-friendly interface; has caused our people to get more motivated than ever."
    :"Vantage Rewards ha sido parte de nuestros esfuerzos renovados para establecer una cultura de reconocimiento al dar a nuestra fuerza laboral la flexibilidad de elegir sus propias recompensas. La función de reconocimiento instantáneo nos facilita recompensar el buen trabajo de inmediato. Esperar ser incentivados, tener la opción de múltiples opciones de recompensa y la interfaz amigable al usuario; ha motivado a nuestra gente más que nunca.",

    "The Rewards and Recognition program we had before was very different from what Vantage Circle offers. After Vantage Circle, everything became a lot easier and structured. When we launched the program we saw an engagement of 55-65% which is quite a good according to me. Right now our plan is to improve our internal communication around it. The most popular feature here is the badges, people are really enjoying giving each other badges."
    :"El programa de Recompensas y Reconocimiento que teníamos antes era muy diferente de lo que ofrece Vantage Circle. Después de Vantage Circle, todo se volvió mucho más fácil y estructurado. Cuando lanzamos el programa vimos un compromiso del 55-65% que es bastante bueno según mi opinión. Ahora nuestro plan es mejorar nuestra comunicación interna al respecto. La función más popular aquí son las insignias, a la gente realmente le gusta darse insignias entre sí.",

    "Vantage Rewards is the first online-based rewards and recognition platform that we’ve implemented. The point-based rewarding system has made tracking of rewards and it’s data more manageable. The report feature has been helpful in understanding the budget and reward allocations. It has also given us the option of analyzing where we are in terms of recognizing our employees. Every organization, like ours, would certainly benefit from using the platform."
    :"Vantage Rewards es la primera plataforma de recompensas y reconocimiento basada en línea que hemos implementado. El sistema de recompensa basado en puntos ha hecho que el seguimiento de recompensas y sus datos sea más manejable. La función de informe ha sido útil para entender el presupuesto y las asignaciones de recompensas. También nos ha dado la opción de analizar dónde estamos en términos de reconocimiento de nuestros empleados. Cada organización, como la nuestra, ciertamente se beneficiaría de usar la plataforma.",

    "Wipro Advantage (powered by Vantage Circle) was launched last year to provide exclusive privileges to our employees pan India. The feedback received from the employees has been very positive and we feel it is an amazing value proposition for our employees."
    :"Wipro Advantage (potenciado por Vantage Circle) fue lanzado el año pasado para proporcionar privilegios exclusivos a nuestros empleados en toda la India. La retroalimentación recibida de los empleados ha sido muy positiva y creemos que es una propuesta de valor increíble para nuestros empleados.",

    "Vantage Circle has transformed our previous manual rewarding process. With the ease of use, streamlined rewards distribution, and flexibility of choosing rewards, our TAT time has reduced considerably. Vantage Circle’s admin dashboard gives us incredible insights into data-backed engagement patterns. We have seen a chain reaction of enhanced work performance in all our teams. It really is the perfect solution to hassle-free rewards and recognition."
    :"Vantage Circle a transformé notre précédent processus de récompense manuel. Grâce à la facilité d'utilisation, à la distribution rationalisée des récompenses et à la flexibilité du choix des récompenses, notre temps de traitement a été considérablement réduit. Le tableau de bord administratif de Vantage Circle nous offre des insights incroyables sur les modèles d'engagement soutenus par des données. Nous avons constaté une réaction en chaîne d'amélioration des performances de travail dans toutes nos équipes. C'est vraiment la solution parfaite pour des récompenses et une reconnaissance sans tracas.",

    "Vantage Circle has made our Rewarding and Recognition process seamless, and we are now empowered to instantly recognize and celebrate great work. Giving the employees monetary awards or acknowledging other internal accomplishments has never been easier. The point-based reward gives employees the flexibility to choose their gift cards from various online/retail stores. It encourages employee motivation by showcasing their achievements of milestones and recognitions via social leaderboard on the platform. It also helps us build employee satisfaction in the long run and a culture where recognition is never delayed."
    :"Vantage Circle ha transformado nuestro proceso de recompensa manual anterior. Con la facilidad de uso, la distribución de recompensas optimizada y la flexibilidad de elegir recompensas, nuestro tiempo de respuesta ha disminuido considerablemente. El panel de administración de Vantage Circle nos brinda increíbles perspectivas sobre patrones de compromiso respaldados por datos. Hemos visto una reacción en cadena de mejora del rendimiento laboral en todos nuestros equipos. Realmente es la solución perfecta para recompensas y reconocimientos sin complicaciones.",

    "I got to know about the Vantage Circle platform from various colleagues who are working with different companies. Vantage Rewards platform helped us route our Rewards and Recognition (R&R) and also helped in tracking our R&R budget allocations. The most amazing feature provided by VC is the points to money variant, i.e 1 point= Rs.1. Moreover, the manager points are really going smoothly and we just wanted this nomination process to be incorporated by the portal. I will definitely recommend the Vantage Rewards platform to other organizations."
    :"Llegué a conocer la plataforma Vantage Circle a través de varios colegas que trabajan en diferentes empresas. La plataforma Vantage Rewards nos ayudó a encaminar nuestras Recompensas y Reconocimiento (R&R) y también ayudó en el seguimiento de nuestras asignaciones de presupuesto R&R. La característica más increíble proporcionada por VC es la variante de puntos a dinero, es decir, 1 punto = Rs.1. Además, los puntos de los gerentes realmente están funcionando sin problemas y solo queríamos que este proceso de nominación fuera incorporado por el portal. Definitivamente recomendaría la plataforma Vantage Rewards a otras organizaciones.",

    "Vantage Circle has helped us revamp our Rewards process entirely and gave us the freedom to choose from various options available online as per the individual specific needs. The online platform has helped boost morale for the employees as part of Escalon Family even during COVID-19. It would not have been possible without such a platform when the employees were scattered across the country. It helped us big time and has been our preferred option for all our employee motivation activities. We are glad to be associated with Vantage Circle and look forward to a long-term partnership with them."
    :"Vantage Circle ha ayudado a renovar completamente nuestro proceso de recompensas y nos ha dado la libertad de elegir entre diversas opciones disponibles en línea según las necesidades específicas de cada individuo. La plataforma en línea ha ayudado a aumentar la moral de los empleados como parte de la familia Escalon incluso durante COVID-19. No habría sido posible sin tal plataforma cuando los empleados estaban dispersos por todo el país. Nos ayudó mucho y ha sido nuestra opción preferida para todas nuestras actividades de motivación de empleados. Estamos contentos de estar asociados con Vantage Circle y esperamos una asociación a largo plazo con ellos.",

    "Vantage Circle Platform was implemented to support existing Reward & Recognition Process. Vantage Reward Points can be availed easily by Employees providing complete freedom to choose from various bouquet of Services and Online options available as per the individual choices (have personalized experience). The online platform made it easier to have instant gratification possible. This Platform is now widely used across all the Functions / Locations. Reward Points are also being given for any Competitions or driving New Initiatives with Spot Prizes given to the Winners. Vantage Reward Points helped in boosting Employees morale even during the pandemic situation. We look forward to a long term partnership with Vantage Circle exploring other features to further enhance R&R Function at Tata Power."
    :"La plataforma Vantage Circle se implementó para respaldar el proceso existente de Recompensa y Reconocimiento. Los puntos de recompensa de Vantage pueden ser fácilmente aprovechados por los empleados proporcionando completa libertad para elegir entre varios ramos de servicios y opciones en línea disponibles según las elecciones individuales (experiencia personalizada). La plataforma en línea facilitó la posibilidad de gratificación instantánea. Esta plataforma ahora es ampliamente utilizada en todas las Funciones/Ubicaciones. También se otorgan puntos de recompensa para cualquier Competencia o impulso de Nuevas Iniciativas con Premios Instantáneos otorgados a los Ganadores. Los puntos de recompensa de Vantage ayudaron a aumentar la moral de los empleados incluso durante la situación pandémica. Esperamos una asociación a largo plazo con Vantage Circle explorando otras características para mejorar aún más la función R&R en Tata Power.",

    "Building a highly engaged workforce is integral to Tata Motors' vision. One of the important pillars for fostering engagement is to celebrate contributions and milestones. This can be best achieved by real-time recognition and celebration. Vantage Circle has been a wonderful partner as we developed our Digital Rewards & Recognition program - Accolades. We value our collaboration with Team Vantage and look forward to working together on offering great experience to our employees."
    :"Construir una fuerza laboral altamente comprometida es integral a la visión de Tata Motors. Uno de los pilares importantes para fomentar el compromiso es celebrar las contribuciones y los hitos. Esto se puede lograr mejor mediante el reconocimiento y la celebración en tiempo real. Vantage Circle ha sido un socio maravilloso mientras desarrollábamos nuestro programa de Recompensas y Reconocimientos Digitales - Accolades. Valoramos nuestra colaboración con el equipo de Vantage y esperamos trabajar juntos para ofrecer una gran experiencia a nuestros empleados.",

    "Implementing Vantage Perks as our employee benefits platform has been a game-changer for our organization. With exclusive offers from leading brands and cashback points on purchases, our employees have found it effortless to utilize the service provided. Moreover, the chosen employee benefits have been customized as per our requirements. Our employees absolutely love it, making them happier and more engaged. We couldn't be more glad for our association with Vantage Perks. I would highly recommend Vantage Perks as it offers a great redemption method and features products aligned with our employees' interests. Taking part in this initiative encourages employees to contribute more to the workplace."
    :"Implementar Vantage Perks como nuestra plataforma de beneficios para empleados ha sido un cambio de juego para nuestra organización. Con ofertas exclusivas de marcas líderes y puntos de reembolso en compras, nuestros empleados han encontrado que el servicio proporcionado es fácil de utilizar. Además, los beneficios para empleados elegidos han sido personalizados según nuestros requisitos. A nuestros empleados les encanta absolutamente, haciéndolos más felices y más comprometidos. No podríamos estar más contentos con nuestra asociación con Vantage Perks. Recomiendo altamente Vantage Perks ya que ofrece un gran método de redención y presenta productos alineados con los intereses de nuestros empleados. Participar en esta iniciativa anima a los empleados a contribuir más en el lugar de trabajo.",

    "We'd like to express our satisfaction with the seamless implementation of Vantage Circle's Recognition Platform. Working with Vantage Circle's team has been an absolute breeze, making it one of the smoothest implementations we've ever undertaken. From day one, their team has been incredibly supportive, addressing all our inquiries and providing guidance at every step of the journey. We met our project timeline and had everything running within just one month. The entire process was incredibly smooth and hassle-free. It was truly an amazing experience!"
    :"Nos gustaría expresar nuestra satisfacción con la implementación sin problemas de la Plataforma de Reconocimiento de Vantage Circle. Trabajar con el equipo de Vantage Circle ha sido una brisa absoluta, haciendo que sea una de las implementaciones más fluidas que hemos emprendido. Desde el primer día, su equipo ha sido increíblemente solidario, atendiendo todas nuestras consultas y proporcionando orientación en cada paso del camino. Cumplimos nuestro cronograma de proyectos y tuvimos todo en funcionamiento en solo un mes. Todo el proceso fue increíblemente fluido y sin problemas. ¡Fue realmente una experiencia increíble!",

    "The need for digitalization, real-time recognition, and access to a wide range of vouchers drove our search for a rewards and recognition platform like Vantage Rewards. What set the platform apart were its customization options, the points validity feature, the user-friendly interface, and the innovative use of hashtags. Our objectives with Vantage Rewards include enhancing employee participation and leveraging analytics for insights. The implementation process has been exceptionally smooth, thanks to great support from the Vantage team, who helped tailor the platform to our specific needs. Their support team was impressively responsive and quick to address any challenges. Based on our experience, we wholeheartedly recommend Vantage Rewards to other organizations. Its vast features, adaptable user interface, and flexibility to meet unique business needs make it an invaluable tool for organizations in a similar position."
    :"La necesidad de digitalización, reconocimiento en tiempo real y acceso a una amplia gama de bonos impulsó nuestra búsqueda de una plataforma de recompensas y reconocimiento como Vantage Rewards. Lo que distinguió a la plataforma fueron sus opciones de personalización, la función de validez de puntos, la interfaz amigable al usuario y el uso innovador de hashtags. Nuestros objetivos con Vantage Rewards incluyen mejorar la participación de los empleados y aprovechar las analíticas para obtener insights. El proceso de implementación ha sido excepcionalmente fluido, gracias al gran apoyo del equipo de Vantage, que ayudó a adaptar la plataforma a nuestras necesidades específicas. Su equipo de soporte fue impresionantemente receptivo y rápido para abordar cualquier desafío. Basado en nuestra experiencia, recomendamos de todo corazón Vantage Rewards a otras organizaciones. Sus vastas características, interfaz de usuario adaptable y flexibilidad para satisfacer necesidades comerciales únicas la convierten en una herramienta invaluable para organizaciones en una posición similar.",

    "Seeking a flexible R&R platform accommodating our diverse workforce, Vantage Rewards emerged as the ideal choice. Its user-friendly interface, customizable features, and array of face-value reward options surpassed competitors. With the goal of enhancing engagement and productivity, Vantage Rewards seamlessly integrated despite minor site-specific challenges. The support team’s promptness and expertise were commendable. We anticipate heightened morale, improved team cohesion, and enhanced KPIs in engagement and performance. Its alignment with our culture makes it recommended for organizations seeking a robust R&R solution."
    : "Buscando una plataforma de R&R flexible que acomode nuestra diversa fuerza laboral, Vantage Rewards surgió como la opción ideal. Su interfaz amigable al usuario, sus características personalizables y su variedad de opciones de recompensa de valor nominal superaron a los competidores. Con el objetivo de mejorar el compromiso y la productividad, Vantage Rewards se integró sin problemas a pesar de pequeños desafíos específicos del sitio. La prontitud y la experiencia del equipo de soporte fueron encomiables. Anticipamos un aumento en la moral, una mejor cohesión de equipo y una mejora en los KPIs de compromiso y rendimiento. Su alineación con nuestra cultura lo hace recomendado para organizaciones que buscan una solución robusta de R&R.",

    "Vantage Circle has been great for us. They met our expectations in every aspect. The pulse tool, a unique feature of their platform, was a massive advantage, and their ability to customize the tool to our needs was exceptional. We appreciated that their updates didn't disrupt our customizations. The sales team was outstanding, mapping out precisely how the tool would benefit our recognition program and enhance our company culture. The implementation process was seamless, thanks to their upfront communication and dedication. Within just 90 days of signing the contract, we were up and running. Vantage Circle's commitment to our success was a pleasant surprise, and their quick response to our customization needs was impressive. It has truly been a transformative experience for our company, embraced by every generation within our organization."
    :"Vantage Circle ha sido genial para nosotros. Cumplieron nuestras expectativas en todos los aspectos. La herramienta Pulse, una característica única de su plataforma, fue una ventaja masiva, y su capacidad para personalizar la herramienta según nuestras necesidades fue excepcional. Apreciamos que sus actualizaciones no interrumpieran nuestras personalizaciones. El equipo de ventas fue sobresaliente, delineando precisamente cómo la herramienta beneficiaría a nuestro programa de reconocimiento y mejoraría nuestra cultura empresarial. El proceso de implementación fue fluido, gracias a su comunicación abierta y dedicación. En solo 90 días después de firmar el contrato, ya estábamos operativos. El compromiso de Vantage Circle con nuestro éxito fue una grata sorpresa, y su rápida respuesta a nuestras necesidades de personalización fue impresionante. Ha sido verdaderamente una experiencia transformadora para nuestra empresa, adoptada por cada generación dentro de nuestra organización.",

    "The experience with the Vantage Circle team has been exceptional. The implementation process proceeded seamlessly, adhering to our agreed-upon timelines. We greatly appreciated the multilingual support provided, which facilitated the entire implementation journey. The Vantage Circle Customer Success team has consistently demonstrated great support and responsiveness. The Customer Success team is always available to help and responds to our requests as quickly as possible. We are very grateful to everyone who has collaborated in our launch and for their support throughout the process. Thanks Vantage, for being a platform that meets our needs. Vantage complies with the support and incorporates two languages and two currencies at the same time. This support covers our population in Puerto Rico and Colombia. Since the launch, we have witnessed a noteworthy increase in employee engagement. Vantage Circle's product and its features are intuitive, making it effortless for our team to grasp and implement. Thanks to Vantage Circle's product, we now feel positioned to enhance our employee experience significantly. We are happy with Vantage Circle team and platform."
    :"La experiencia con el equipo de Vantage Circle ha sido excepcional. El proceso de implementación procedió sin problemas, adheriéndose a nuestros plazos acordados. Apreciamos enormemente el soporte multilingüe proporcionado, que facilitó todo el viaje de implementación. El equipo de Éxito del Cliente de Vantage Circle ha demostrado constantemente un gran apoyo y receptividad. El equipo de Éxito del Cliente siempre está disponible para ayudar y responde a nuestras solicitudes lo más rápido posible. Estamos muy agradecidos con todos los que han colaborado en nuestro lanzamiento y por su apoyo durante todo el proceso. Gracias Vantage, por ser una plataforma que satisface nuestras necesidades. Vantage cumple con el soporte e incorpora dos idiomas y dos monedas al mismo tiempo. Este soporte cubre nuestra población en Puerto Rico y Colombia. Desde el lanzamiento, hemos sido testigos de un notable aumento en el compromiso de los empleados. El producto de Vantage Circle y sus características son intuitivos, facilitando que nuestro equipo los comprenda e implemente. Gracias al producto de Vantage Circle, ahora nos sentimos posicionados para mejorar significativamente la experiencia de nuestros empleados. Estamos contentos con el equipo y la plataforma de Vantage Circle.",

    "Vantage R&R revolutionized recognition at VFI-SLK. While our prior methods were decent, their automation and diverse rewards transformed our approach. Before, we had limited options for recognizing employees, but the current automated system provides various categories for rewarding the team. Implementing Vantage R&R has proven to be useful and seamlessly integrated into our workflow, making the entire process effortless. Although I'm still exploring features, the initial experience is promising. While specific metrics aren't quantified, the potential for positive impact is evident. I wholeheartedly recommend Vantage R&R for its user-friendly, efficient, and diverse staff recognition and rewards approach."
    :"Vantage R&R revolucionó el reconocimiento en VFI-SLK. Aunque nuestros métodos anteriores eran decentes, su automatización y diversas recompensas transformaron nuestro enfoque. Antes, teníamos opciones limitadas para reconocer a los empleados, pero el sistema automatizado actual proporciona diversas categorías para recompensar al equipo. Implementar Vantage R&R ha demostrado ser útil y se integró sin problemas en nuestro flujo de trabajo, haciendo que todo el proceso fuera sin esfuerzo. Aunque todavía estoy explorando características, la experiencia inicial es prometedora. Aunque no se cuantifican métricas específicas, el potencial de impacto positivo es evidente. Recomiendo de todo corazón Vantage R&R por su enfoque amigable al usuario, eficiente y diverso para el reconocimiento y las recompensas del personal.",

    
    "AVP and Head of Internal Communication": "AVP y Jefe de Comunicación Interna",
    "Human Resources": "Recursos Humanos",
    "HR, Employee Experience & Internal Communications": "RH, Experiencia del Empleado y Comunicaciones Internas",
    "Internal Communications Manager": "Gerente de Comunicaciones Internas",
    "Employee Relations": "Relaciones con Empleados",
    "Vice President and HR Head": "Vicepresidente y Jefe de RRHH",
    "HR Business Partner": "Socio Comercial de RH",
    "Associate Manager": "Gerente Asociado",
    "Associate Manager- HR, Voya India (Formerly VFI SLK)": "Gerente Asociado de RRHH, Voya India (anteriormente VFI SLK)",
    "Lead HR": "Jefe de RRHH",
    "Head – R&R & Quality Improvement": "Jefe – R&R y Mejora de Calidad",
    "Head – Performance Management, Compensation & Benefits": "Jefe – Gestión del Desempeño, Compensación y Beneficios",
    "HR Executive": "Ejecutivo de RRHH",
    "VP Human Resources, Transformation Projects": "VP de Recursos Humanos, Proyectos de Transformación",
    "Manager HR": "Gerente de RRHH",
    " Regional HR Advisor, South Asia, People & Talent": "Asesor de RRHH Regional, Asia del Sur, Personas y Talento",
    "Company Representative": "Representante de la Empresa",
    "HR, Voya India (Formerly VFI SLK)": "RH, Voya India (anteriormente VFI SLK)"
}